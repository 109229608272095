import Moment from 'moment'

export default function timeago(value) {
  if (!value || value <= 0) {
    return ''
  }
  if (Moment.isMoment(value)) {
    return value.fromNow()
  }
  if (/^\d+$/.test(value)) {
    return Moment.unix(value / 1000).fromNow()
  }
  return value
}
