import { generateId } from '@utils/id'
import SortBy from 'lodash/sortBy'
import Invert from 'lodash/invert'
import { getEncryptedPassword, getDecryptedPassword } from '@utils/password'

import {
  transformQualificationForServer,
  transformQualification,
  transformQualificationWithDecisionQualForServer,
  transformQualificationWithDecisionQual,
} from '@data/qualification'
// used for messages rendering
export const chatBrainType = {
  USER_INPUT_TEXT: 'user_input_text',
  USER_INPUT_DATE: 'user_input_date',
  USER_STATIC_CHOICE: 'user_static_choice',
  USER_REF_CHOICE: 'user_ref_choice',
  BOT_RESPONSE_TEXT: 'bot_response_text',
  BUTTON: 'button',
  MULTIFLOW: 'multiflow',
  CARD: 'card',
  IMAGE: 'image',
  REDIRECT_BOT_RESPONSE: 'redirect_bot_response',
  ACTION_UTILITY: 'action_utility',
  CHAT_FLOW_UTILITY: 'chat_flow_utility',
  DECISION_UTILITY: 'decision_utility',
  PLUGIN_UTILITY: 'plugin_utility',
  SEARCH_UTILITY: 'search_utility',
  TRANSFER_TO_TECHNICIAN: 'transfer_to_technician',
  ERROR_RESPONSE: 'error_response',
  SUCESS_RESPONSE: 'sucess_response',
}

// used for build flow bulder
export const brainTypeConstants = {
  // user
  USER_TEXT: 'user_text',
  USER_STATIC_CHOICE: 'user_static_choice',
  USER_DATE: 'user_date',
  USER_REF_CHOICE: 'user_ref_choice',
  // bot
  BOT_TEXT: 'bot_text',
  BOT_BUTTON: 'bot_button',
  BOT_MULTIFLOW: 'bot_multiflow',
  BOT_CARD: 'bot_card',
  BOT_IMAGE: 'bot_image',
  BOT_REDIRECT: 'bot_redirect',
  BOT_ERROR_RESPONSE: 'error_response',
  // utility
  UTILITY_ACTION: 'utility_action',
  UTILITY_CHAT_FLOW: 'utility_chat_flow',
  UTILITY_DECISION: 'utility_decision',
  UTILITY_GOTO: 'utility_goto',
  SUCESS_RESPONSE: 'sucess_response',
  FAILER_RESPONSE: 'failer_response',
  PLUGIN_UTILITY: 'plugin_utility',
  SEARCH_UTILITY: 'search_utility',
  UTILITY_TRANSFER_TO_TECHNICIAN: 'transfer_to_technician',

  ERROR_RESPONSE: 'error_response',
}

export const allowedMultipleChildBrains = [
  brainTypeConstants.BOT_MULTIFLOW,
  brainTypeConstants.UTILITY_DECISION,
]

export const disAllowedChildren = [
  brainTypeConstants.UTILITY_GOTO,
  brainTypeConstants.UTILITY_CHAT_FLOW,
  brainTypeConstants.UTILITY_TRANSFER_TO_TECHNICIAN,
  brainTypeConstants.UTILITY_ACTION,
  brainTypeConstants.PLUGIN_UTILITY,
  brainTypeConstants.SEARCH_UTILITY,
]

export const dropOnlyOnLast = [
  brainTypeConstants.UTILITY_ACTION,
  brainTypeConstants.PLUGIN_UTILITY,
  brainTypeConstants.SEARCH_UTILITY,
]

export const brainTypeMap = {
  // user
  [brainTypeConstants.USER_TEXT]: 'TextUserInputChatBrainRest',
  [brainTypeConstants.USER_STATIC_CHOICE]: 'StaticChoiceUserInputChatBrainRest',
  [brainTypeConstants.USER_DATE]: 'DateUserInputChatBrainRest',
  [brainTypeConstants.USER_REF_CHOICE]: 'RefChoiceUserInputChatBrainRest',
  // bot
  [brainTypeConstants.BOT_TEXT]: 'TextBotResponseChatBrainRest',
  [brainTypeConstants.BOT_BUTTON]: 'ButtonBotResponseChatBrainRest',
  [brainTypeConstants.BOT_MULTIFLOW]: 'MultiFlowBotResponseChatBrainRest',
  [brainTypeConstants.BOT_CARD]: 'CardBotResponseChatBrainRest',
  [brainTypeConstants.BOT_IMAGE]: 'ImageBotResponseChatBrainRest',
  [brainTypeConstants.BOT_REDIRECT]: 'RedirectBotResponseChatBrainRest',
  [brainTypeConstants.BOT_ERROR_RESPONSE]: 'FailerBotResponseChatBrainRest',
  // utility
  [brainTypeConstants.UTILITY_ACTION]: 'ActionUtilityChatBrainRest',
  [brainTypeConstants.UTILITY_CHAT_FLOW]: 'ChatFlowUtilityChatBrainRest',
  [brainTypeConstants.UTILITY_GOTO]: 'GoToStepUtilityChatBrainRest',
  [brainTypeConstants.UTILITY_DECISION]: 'DecisionUtilityChatBrainRest',
  [brainTypeConstants.SUCESS_RESPONSE]: 'SucessBotResponseChatBrainRest',
  [brainTypeConstants.FAILER_RESPONSE]: 'FailerBotResponseChatBrainRest',
  [brainTypeConstants.FAILER_RESPONSE]:
    'TransferToTechnicianUtilityChatBrainRest',
  [brainTypeConstants.PLUGIN_UTILITY]: 'PluginUtilityChatBrainRest',
  [brainTypeConstants.SEARCH_UTILITY]: 'SearchFilterBotResponseChatBrainRest',
  [brainTypeConstants.UTILITY_TRANSFER_TO_TECHNICIAN]:
    'TransferToTechnicianUtilityChatBrainRest',
}

export function transformBrainType(brain, type) {
  return {
    ...(type.indexOf('user_') >= 0 ? transformUserBrainType(brain) : {}),
    ...(type.indexOf('bot_') >= 0 ? transformBotBrainType(brain) : {}),
    ...(type.indexOf('utility_') >= 0 ||
    [
      brainTypeConstants.UTILITY_TRANSFER_TO_TECHNICIAN,
      brainTypeConstants.PLUGIN_UTILITY,
      brainTypeConstants.SEARCH_UTILITY,
      brainTypeConstants.UTILITY_DECISION,
    ].indexOf(type) >= 0
      ? transformUtilityBrainType(brain, type)
      : {}),
  }
}
export function transformBrainTypeForServer(brain, type) {
  return {
    ...(type.indexOf('user_') >= 0 ? transformUserBrainType(brain) : {}),
    ...(type.indexOf('bot_') >= 0 ? transformBotBrainType(brain) : {}),
    ...(type.indexOf('utility_') >= 0 ||
    [
      brainTypeConstants.UTILITY_TRANSFER_TO_TECHNICIAN,
      brainTypeConstants.PLUGIN_UTILITY,
      brainTypeConstants.SEARCH_UTILITY,
      brainTypeConstants.UTILITY_DECISION,
    ].indexOf(type) >= 0
      ? transformUtilityBrainTypeForServer(brain, type)
      : {}),
  }
}

export function transformUserBrainType(brain) {
  return {
    variableName: brain.variableName,
    acknowledgementMessage: brain.acknowledgementMessage,
    confirmationMessage: brain.confirmationMessage,
    retryCount: brain.retryCount,
    ...(brain.retryCount ? { errorMessage: brain.errorMessage } : {}),
    defaultValue: brain.defaultValue,
    promptMessage: brain.promptMessage,
    // USER_TEXT
    message: brain.message,

    // USER_DATE
    dateInputType: brain.dateInputType,

    // USER_STATIC_CHOICE
    options: (brain.options || []).map((i) => ({
      guid: generateId(),
      objKey: i.objKey,
      objValue: i.objValue,
    })),

    // USER_REF_CHOICE
    refChoiceType: brain.refChoiceType,
    customFieldId: brain.customFieldId,
    model: brain.model,
    serviceCatalogId: brain.serviceCatalogId,
    textUserInputType: brain.textUserInputType,
    showInContext: brain.showInContext,
  }
}

export function transformBotBrainType(brain) {
  return {
    // BOT_TEXT
    response: brain.response,

    // BOT_BUTTON
    displayName: brain.displayName,
    payload: brain.payload,

    // BOT_MULTIFLOW
  }
}

export function transformUtilityBrainType(brain, type) {
  return {
    // UTILITY_ACTION
    actionType: brain.actionType,
    model: brain.model,
    serviceCatalogId: brain.serviceCatalogId,
    // mappingField: brain.mappingField,
    mappingField: (brain.mappingField || []).map((i) => ({
      guid: generateId(),
      objKey: i.objKey,
      objValue: i.objValue,
    })),
    // outputField: brain.outputField,
    ...(type === brainTypeConstants.PLUGIN_UTILITY
      ? {
          outputField: (brain.outputField || []).map((i) => ({
            guid: generateId(),
            objKey: i.objKey,
            objValue: i.objValue,
          })),
        }
      : {
          outputField: (brain.outputField || []).map((i) => ({
            guid: generateId(),
            objKey: i,
          })),
        }),
    successNextAction: brain.successNextAction,
    responseMessage: brain.responseMessage,
    failerNextAction: brain.failerNextAction,

    // PLUGIN_UTILITY
    pluginIdentifier: brain.pluginIdentifier,
    noDataMessage: brain.noDataMessage,

    // SEARCH_UTILITY
    ...(type === brainTypeConstants.SEARCH_UTILITY
      ? {
          qualifications: transformQualification(
            brain.qualification || { groups: [], operators: [] }
          ),
        }
      : {}),

    // UTILITY_CHAT_FLOW
    chatFlowId: brain.chatFlowId,

    // UTILITY_DECISION
    ...(type === brainTypeConstants.UTILITY_DECISION
      ? {
          qualifications: transformQualificationWithDecisionQual(
            brain.qualification || { groups: [], operators: [] }
          ),
        }
      : {}),

    // UTILITY_GOTO
    nextStepId: brain.nextStepId,

    // UTILITY_TRANSFER_TO_TECHNICIAN
    response: brain.response,
  }
}

export function transformUtilityBrainTypeForServer(brain, type) {
  return {
    // UTILITY_ACTION
    actionType: brain.actionType,
    model: brain.model,
    serviceCatalogId: brain.serviceCatalogId,
    // mappingField: brain.mappingField,
    mappingField: (brain.mappingField || []).map((i) => ({
      guid: generateId(),
      objKey: i.objKey,
      objValue: i.objValue,
    })),
    // outputField: brain.outputField,
    ...(type === brainTypeConstants.PLUGIN_UTILITY
      ? {
          outputField: (brain.outputField || []).map((i) => ({
            guid: generateId(),
            objKey: i.objKey,
            objValue: i.objValue,
          })),
        }
      : {
          outputField: (brain.outputField || []).map((i) => i.objKey),
        }),
    successNextAction: brain.successNextAction,
    responseMessage: brain.responseMessage,
    failerNextAction: brain.failerNextAction,

    // PLUGIN_UTILITY
    pluginIdentifier: brain.pluginIdentifier,
    noDataMessage: brain.noDataMessage,

    // SEARCH_UTILITY
    ...(type === brainTypeConstants.SEARCH_UTILITY
      ? {
          qualification: transformQualificationForServer(brain.qualifications),
        }
      : {}),

    // UTILITY_CHAT_FLOW
    chatFlowId: brain.chatFlowId,

    // UTILITY_DECISION
    ...(type === brainTypeConstants.UTILITY_DECISION
      ? {
          qualification: transformQualificationWithDecisionQualForServer(
            brain.qualifications
          ),
        }
      : {}),

    // UTILITY_GOTO
    nextStepId: brain.nextStepId,

    // UTILITY_TRANSFER_TO_TECHNICIAN
    response: brain.response,
  }
}

export function transformBrain(brain) {
  let type = Invert(brainTypeMap)[brain.type]
  if (!type) {
    throw new Error(`Brain with type ${type} is not defined`)
  }
  return {
    guid: brain.guid || generateId(),
    id: brain.id,
    name: brain.name,
    variableName: brain.variableName,
    parentId: brain.prevNode === 0 ? -1 : brain.prevNode,
    description: brain.description,
    chatBrainInfos: SortBy(brain.chatBrainInfos || [], 'objOrder'),
    type,
    ...transformBrainType(brain, type),
  }
}

export function transformBrainForServer(brain) {
  let type = brainTypeMap[brain.type]
  if (!type) {
    throw new Error(`Brain with type ${type} is not defined`)
  }
  return {
    name: brain.name,
    variableName: brain.variableName,
    prevNode: brain.parentId === -1 ? 0 : brain.parentId,
    childNode: brain.childId,
    description: brain.description,
    children: brain.children,
    chatBrainInfos: (brain.chatBrainInfos || []).map((item, index) => ({
      chatBrainId: item.chatBrainId,
      objOrder: index + 1,
    })),
    type,
    ...transformBrainTypeForServer(brain, brain.type),
  }
}

export function transformChatFlow(chatFlow) {
  return {
    id: chatFlow.id,
    name: chatFlow.name,
    description: chatFlow.description,
    enabled: !chatFlow.disabled,
    examples: chatFlow.exampleList.map((i) => ({
      example: i,
      guid: generateId(),
    })),
    canEdit: chatFlow.updatebleOob,
    canDelete: chatFlow.deleteableOob,
    applicableChatTypes: chatFlow.applicableChatTypes,
  }
}

export function transformChatFlowForServer(chatFlow) {
  const applicableChatTypes = chatFlow.applicableChatTypes.filter(
    (type) => type !== 'all'
  )
  return {
    name: chatFlow.name,
    description: chatFlow.description,
    disabled: !chatFlow.enabled,
    exampleList: (chatFlow.examples || []).map((e) => e.example),
    applicableChatTypes,
  }
}
export function transformSlackAppConfig(slackappConfig) {
  return {
    id: slackappConfig.id,
    clientId: slackappConfig.clientId
      ? getDecryptedPassword(slackappConfig.clientId)
      : undefined,
    clientSecret: slackappConfig.clientSecret
      ? getDecryptedPassword(slackappConfig.clientSecret)
      : undefined,
    slackappConfigEnabled: slackappConfig.slackappConfigEnabled,
    name: slackappConfig.name,
    enabled: !slackappConfig.disabled,
    domainName: slackappConfig.domainName,
    sharableUrl: slackappConfig.sharableUrl,
  }
}
export function transformSlackAppConfigForServer(slackappConfig) {
  return {
    name: slackappConfig.name,
    clientId: slackappConfig.clientId
      ? getEncryptedPassword(slackappConfig.clientId)
      : undefined,
    clientSecret: slackappConfig.clientSecret
      ? getEncryptedPassword(slackappConfig.clientSecret)
      : undefined,
    slackappConfigEnabled: slackappConfig.slackappConfigEnabled,
    disabled: !slackappConfig.enabled,
    domainName: slackappConfig.domainName,
    sharableUrl: slackappConfig.sharableUrl,
  }
}

export function transformViberAppConfig(viberAppConfig) {
  return {
    name: viberAppConfig.name,
    clientId: viberAppConfig.clientId
      ? getDecryptedPassword(viberAppConfig.clientId)
      : undefined,
    enabled: !viberAppConfig.disabled,
  }
}

export function transformViberAppConfigForServer(viberAppConfig) {
  return {
    name: viberAppConfig.name,
    disabled: !viberAppConfig.enabled,
    clientId: viberAppConfig.clientId
      ? getEncryptedPassword(viberAppConfig.clientId)
      : undefined,
  }
}

export function transformTeamsAppConfig(config) {
  return {
    clientId: config.clientId
      ? getDecryptedPassword(config.clientId)
      : undefined,
    password: config.password,
    enabled: !config.disabled,
    fileAttachments: config.fileAttachments,
    allowNotification: config.allowNotification,
  }
}

export function transformTeamsAppConfigForServer(config) {
  return {
    clientId: config.clientId
      ? getEncryptedPassword(config.clientId)
      : undefined,
    password: config.password,
    disabled: !config.enabled,
    fileAttachments: config.fileAttachments,
    allowNotification: config.allowNotification,
  }
}

// Messenger App - Whatsapp Configuration Transformers

export function transformMessagingAppWhatsappConfig(config) {
  return {
    clientId: config.clientId
      ? getDecryptedPassword(config.clientId)
      : undefined,
    password: config.password,
    enabled: !config.disabled,
    clientNumber: config.clientNumber,
  }
}

export function transformMessagingAppWhatsappConfigForServer(config) {
  return {
    clientId: config.clientId
      ? getEncryptedPassword(config.clientId)
      : undefined,
    password: config.password,
    disabled: !config.enabled,
    clientNumber: config.clientNumber,
  }
}

// Virtual-Agent - Whatsapp Configuration Crud Transformers

export function transformWhatsappConfig(config) {
  return {
    enabled: !config.disabled,
    phoneId: config.phoneId,
    accessId: config.accessId,
    allowNotification: config.allowNotification,
    clientId: config.clientId
      ? getDecryptedPassword(config.clientId)
      : undefined,
  }
}

export function transformWhatsappConfigForServer(config) {
  return {
    disabled: !config.enabled,
    phoneId: config.phoneId,
    accessId: config.accessId,
    allowNotification: config.allowNotification,
    clientId: config.clientId
      ? getEncryptedPassword(config.clientId)
      : undefined,
  }
}

export function transformTelegramAppConfig(telegramappConfig) {
  return {
    id: telegramappConfig.id,
    clientId: telegramappConfig.clientId
      ? getDecryptedPassword(telegramappConfig.clientId)
      : undefined,
    enabled: !telegramappConfig.disabled,
  }
}

export function transformTelegramAppConfigForServer(telegramappConfig) {
  return {
    clientId: telegramappConfig.clientId
      ? getEncryptedPassword(telegramappConfig.clientId)
      : undefined,
    disabled: !telegramappConfig.enabled,
  }
}

export function transformMessengerAppConfig(messengerappConfig) {
  return {
    id: messengerappConfig.id,
    name: messengerappConfig.name,
    clientId: messengerappConfig.clientId
      ? getDecryptedPassword(messengerappConfig.clientId)
      : undefined,
    pageId: messengerappConfig.pageId,
    enabled: !messengerappConfig.disabled,
    archived: messengerappConfig.removed,
  }
}

export function transformMessengerAppConfigForServer(messengerappConfig) {
  return {
    id: messengerappConfig.id,
    name: messengerappConfig.name,
    clientId: messengerappConfig.clientId
      ? getEncryptedPassword(messengerappConfig.clientId)
      : undefined,
    pageId: messengerappConfig.pageId,
    disabled: !messengerappConfig.enabled,
  }
}

export function transformMessengerAppConfigSettings(messengerappConfig) {
  return {
    accessToken: messengerappConfig.accessToken,
  }
}

export function transformVariable(variable) {
  return {
    id: variable.id,
    propKey: variable.propKey,
    variable: variable.variable,
    variableName: variable.variableName,
    canDelete: variable.deleteableOob,
    canUpdate: variable.updatebleOob,
  }
}

export function transformVariableForServer(data) {
  return {
    propKey: data.propKey,
    variable: data.variable,
    variableName: data.variableName,
  }
}

export function transformMessageOptions(data) {
  const options = Array.isArray(data.options)
    ? data.options
    : data.options.cardList
    ? data.options.cardList
    : [data.options]
  if (options && options.filter((e) => e).length) {
    return {
      options: options.map((option) => {
        if (option.response) {
          return {
            rightHeaderLink: option.rightHeaderLink,
            leftHeaderLink: option.leftHeaderLink,
            response: Object.keys(option.response).map((o) => ({
              key: o,
              text: option.response[o],
            })),
          }
        } else {
          return {
            response: Object.keys(option).map((o) => ({
              key: o,
              text: option[o],
            })),
          }
        }
      }),
    }
  }
  return {}
}

export function transformMessage(data) {
  return {
    id: data.id || generateId(),
    message: data.message,
    chatBrainValue: data.chatBrainValue,
    chatBrainId: data.chatBrainId,
    inputType: data.inputType,
    owner: data.userId,
    ownerName: data.userName,
    date: data.createdTime,
    fileAttachments: data.fileAttachments,
    responseType: data.responseType || chatBrainType.USER_INPUT_TEXT,
    acknowledgementMessage: data.acknowledgementMessage,
    confirmationMessage: data.confirmationMessage,
    groupId: data.groupId,
    ...(data.options ? transformMessageOptions(data) : {}),
    // options for static choice and action utility
    // ...(data.responseType === chatBrainType.PLUGIN_UTILITY ||
    // data.responseType === chatBrainType.SEARCH_UTILITY
    //   ? {
    //       options:
    //         data.options && data.options.length
    //           ? data.options.map((option) => {
    //               return Object.keys(option).map((o) => ({
    //                 key: o,
    //                 text: option[o],
    //               }))
    //             })
    //           : [],
    //     }
    //   : {
    //       options: data.options
    //         ? Object.keys(data.options).map((o) => ({
    //             key: o,
    //             text: data.options[o],
    //           }))
    //         : [],
    //     }),
  }
}

export function transformMessageForServer(data) {
  return {
    message: data.message,
    chatBrainValue: data.chatBrainValue,
    multiSelectMap: data.multiSelectMap,
    chatBrainId: data.chatBrainId,
    inputType: data.inputType,
    userId: data.owner,
    userName: data.ownerName,
    createdTime: data.date,
    responseType: data.responseType || chatBrainType.USER_INPUT_TEXT,
    acknowledgementMessage: data.acknowledgementMessage,
    confirmationMessage: data.confirmationMessage,
  }
}
