export function transformCheckList(checklist) {
  return {
    id: checklist.id,
    name: checklist.name,
    // @TODO replace system name
    systemName: checklist.name,
    text: checklist.name,
    key: checklist.id,
    checked: checklist.checked,
    createdById: checklist.createdById,
    disabled: checklist.inActive,
    oob: checklist.oob,
    canDelete: checklist.deleteableOob,
    canEdit: checklist.updatebleOob,
  }
}
export function transformCheckListForServer(checklist) {
  return {
    name: checklist.name,
    checked: checklist.checked,
  }
}
