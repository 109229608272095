import {
  transformQualificationForServer,
  transformQualification,
} from './qualification'
import { transformActionForServer, transformAction } from './action'

export function transformScenario(scenario) {
  const block = scenario.blocks[0]
  return {
    id: scenario.id,
    name: scenario.name,
    moduleName: scenario.refModel,
    ...(scenario.refId ? { parentId: scenario.refId } : {}),
    description: scenario.description,
    qualifications: transformQualification(
      block.qualification || { groups: [], operators: [] }
    ),
    actions: (block.ifActionSet || []).map(transformAction),
    technicianGroups: scenario.groupIds || [],
    technicians: scenario.userIds || [],
  }
}

export function transformScenarioForServer(scenario) {
  const block = {}
  if (scenario.qualifications) {
    block.qualification = transformQualificationForServer(
      scenario.qualifications
    )
  }
  if (scenario.actions) {
    block.ifActionSet = scenario.actions.map(transformActionForServer)
  }
  return {
    name: scenario.name,
    description: scenario.description,
    blocks: [block],
    groupIds: scenario.technicianGroups,
    userIds: scenario.technicians,
  }
}

export function transformScenarioForList(scenario) {
  return {
    id: scenario.id,
    moduleName: scenario.refModel,
    ...(scenario.refId ? { parentId: scenario.refId } : {}),
    name: scenario.name,
    description: scenario.description,
    canDelete: scenario.deleteableOob,
    enabled: !scenario.disabled,
  }
}
