export function transformSupportPortalForList(data) {
  return {
    id: data.id,
    name: data.name,
    companyId: data.companyId,
    supportPortalUrl: data.supportPortalUrl,
    brandingId: data.brandingId,
    supportPortalConfigId: data.supportPortalConfigId,
    ssoConfigId: data.ssoConfigId,
    enforceSsoLoginOnly: data.enforceSsoLoginOnly,
    enabled: !data.disabled,
  }
}

export function transformSupportPortalForServer(data) {
  return {
    name: data.name,
    companyId: data.companyId,
    supportPortalUrl: data.supportPortalUrl,
    disabled: !data.enabled,
    ssoConfigId: data.ssoConfigId,
    enforceSsoLoginOnly: data.enforceSsoLoginOnly,
  }
}
