export function transformGlCodeForServer(glCode) {
  return {
    id: glCode.id,
    name: glCode.name,
    glCode: glCode.glCode,
    description: glCode.description,
  }
}

export function transformGlCodeForClient(glCode) {
  return {
    id: glCode.id,
    name: glCode.name,
    glCode: glCode.glCode,
    description: glCode.description,
    canEdit: glCode.updatebleOob,
    canDelete: glCode.deleteableOob,
  }
}

export function transformCostCenterForServer(costCenter) {
  return {
    id: costCenter.id,
    name: costCenter.name,
    costCenterCode: costCenter.costCenterCode,
    technicianId: costCenter.technicianId,
    departmentId: costCenter.departmentId,
    companyId: costCenter.companyId,
    description: costCenter.description,
  }
}

export function transformCostCenterForClient(costCenter) {
  return {
    id: costCenter.id,
    name: costCenter.name,
    costCenterCode: costCenter.costCenterCode,
    technicianId: costCenter.technicianId,
    departmentId: costCenter.departmentId,
    companyId: costCenter.companyId,
    description: costCenter.description,
    canEdit: costCenter.updatebleOob,
    canDelete: costCenter.deleteableOob,
  }
}

export function transformAddressForClient(address) {
  return {
    id: address.id,
    name: address.name,
    streetLine: address.streetLine,
    city: address.city,
    state: address.state,
    country: address.country,
    landmark: address.landmark,
    zipCode: address.zipCode,
  }
}

export function transformAddressForServer(address) {
  return {
    id: address.id,
    name: address.name,
    streetLine: address.streetLine,
    city: address.city,
    state: address.state,
    country: address.country,
    landmark: address.landmark,
    zipCode: address.zipCode,
  }
}
