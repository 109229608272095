import {
  tranformFlotoScheduleForServer,
  transformFlotoSchedule,
} from '@data/report'

export function transformFolder(folder) {
  return {
    id: folder.id,
    name: folder.name,
    description: folder.description,
    readPermission: folder.readPermission,
    readUsers: folder.readPermissionUserIds,
    writePermission: folder.writePermission,
    writeUsers: folder.writePermissionUserIds,
    count: folder.knowledgeCount,
    canEdit: folder.updatebleOob,
    canDelete: folder.deleteableOob,
    isTrash: (folder.systemName || '').toLowerCase() === 'trash',
    companyIds: folder.companyIds,
    techReadPermission: folder.techReadPermission,
    readPermissionTechnicianIds: folder.readPermissionTechnicianIds,
    ownerId: folder.ownerId,
  }
}

export function transformFolderForServer(folder) {
  return {
    id: folder.id,
    name: folder.name,
    description: folder.description,
    companyIds: folder.companyIds,
    readPermission: folder.readPermission,
    readPermissionUserIds: folder.readUsers,
    writePermission: folder.writePermission,
    techReadPermission: folder.techReadPermission,
    writePermissionUserIds: folder.writeUsers,
    readPermissionTechnicianIds: folder.readPermissionTechnicianIds,
    ownerId: folder.ownerId,
  }
}

export function transformKnowledgeForServer(kb) {
  return {
    subject: kb.subject,
    folderId: kb.folderId,
    fileAttachments: kb.fileAttachments,
    kbStatus: kb.status,
    tags: kb.tags,
    content: kb.content,
    expiryDate: kb.expiryDate,
  }
}

export function transformKnowledgeForList(kb) {
  return {
    id: kb.id,
    name: kb.name,
    subject: kb.subject,
    folderId: kb.folderId,
    folderName: kb.folderName,
    positiveFeedback: kb.positiveFeedback,
    negativeFeedback: kb.negativeFeedBack,
    createdAt: kb.createdTime,
    updatedAt: kb.updatedTime,
    owner: kb.createdById,
    status: kb.kbStatus,
    content: kb.content,
    relationId: kb.relationId,
    fileAttachments: kb.fileAttachments,
    approvalStatus: kb.approvalStatus,
    expiryDate: kb.expiryDate,
  }
}

export function transformKnowledge(kb) {
  return {
    id: kb.id,
    name: kb.name,
    subject: kb.subject,
    folderId: kb.folderId,
    folderName: kb.folderName,
    fileAttachments: kb.fileAttachments,
    status: kb.kbStatus,
    tags: kb.tags,
    content: kb.content,
    positiveFeedback: kb.positiveFeedback,
    negativeFeedback: kb.negativeFeedBack,
    hasGivenFeedback: !!kb.feedback,
    trashed: kb.trashed,
    isHelpfulFeedback: kb.feedback ? kb.feedback.helpful : false,
    createdAt: kb.createdTime,
    updatedAt: kb.updatedTime,
    canEdit: kb.updatebleOob,
    canDelete: kb.deleteableOob,
    owner: kb.createdById,
    totalRead: (kb.analytic || {}).totalRead || 0,
    relationId: kb.relationId,
    approvalStatus: kb.approvalStatus,
    expiryDate: kb.expiryDate,
    ableToAddReview: kb.canCreateReview,
  }
}

export function transformKbReviewSchedule(schedule) {
  return {
    ...transformFlotoSchedule(schedule),
    id: schedule.id,
    parentId: schedule.objectId,
    gracePeriod: schedule.gracePeriod,
    recipientKeywords: schedule.recipientKeywords,
    technicianGroupIds: schedule.technicianGroupIds,
    requesterGroupIds: schedule.requesterGroupIds,
    technicianIds: schedule.technicianIds,
    requesterIds: schedule.requesterIds,
    emails: schedule.emails,
  }
}
export function transformKbReviewScheduleForServer(schedule) {
  return {
    objectId: schedule.parentId,
    flotoSchedule: tranformFlotoScheduleForServer(schedule),
    gracePeriod: schedule.gracePeriod,
    recipientKeywords: schedule.recipientKeywords,
    technicianGroupIds: schedule.technicianGroupIds,
    requesterGroupIds: schedule.requesterGroupIds,
    technicianIds: schedule.technicianIds,
    requesterIds: schedule.requesterIds,
    emails: schedule.emails,
  }
}
