import Invert from 'lodash/invert'
import Constants from '@constants'
import { getRootTranslator } from '@utils/get-module-translator'
import {
  transformOperator,
  transformOperatorForServer,
  unaryOperators,
  betweenOperators,
  fieldValueOperators,
} from './operator'
import { transformValueTypeForServer, transformValueType } from './value-type'
import {
  buildFlatQualificationStructure,
  OperandTypeMap,
  buildSubQueryQualificationStructure,
  buildRelationalQualificationStructure,
} from './qualification'

export function isArchivedQualExist(quals) {
  return !!quals.find((q) => q.paramName === 'removed')
}

export function isSpamQualExist(quals) {
  return !!quals.find((q) => q.paramName === 'spam')
}

export function transformSearchForServer(moduleName, search) {
  return {
    model: moduleName,
    name: search.name,
    visibleTo: search.scope,
    groupId: search.scope === 'technician_in_group' ? search.group : null,
    qualification: transformSearchCriteriasForServer(
      moduleName,
      search.qualification
    ),
    attributes: search.attributes,
  }
}

export function transformSearch(moduleName, search) {
  return {
    key: search.id,
    id: search.id,
    name: search.name,
    scope: search.visibleTo,
    systemName: (search.systemName || '').toLowerCase(),
    group: search.groupId,
    canUpdate: search.updatebleOob,
    canDelete: search.deleteableOob,
    createdAt: search.createdTime,
    qualifications: ((search.qualification || {}).quals || []).reduce(
      (result, q) => result.concat(transformSearchCriteria(moduleName, q)),
      []
    ),
    attributes: search.attributes,
  }
}

export function transformSearchCriteria(moduleName, qualification) {
  const __t = getRootTranslator()
  if (qualification.type === 'KeywordQualificationRest') {
    return qualification.keywordSet.map((k) => ({
      valueType: 'keyword',
      keyword: k,
      value: k,
    }))
  } else if (qualification.type === 'FlotoSubQueryRest') {
    return [transformSubQuery(moduleName, qualification)]
  } else {
    const operator = transformOperator(qualification.operator)
    const reverseOperandType = Invert(OperandTypeMap)
    return [
      {
        type: qualification.type,
        description: qualification.description,
        qualContext: qualification.qualContext,
        paramName: String(
          qualification.leftOperand.key || qualification.leftOperand.value || ''
        ).replace(`${moduleName}.`, ''),
        operator: {
          name: __t(operator),
          value: operator,
        },
        paramType: reverseOperandType[qualification.leftOperand.type],
        ...(unaryOperators.indexOf(operator) === -1
          ? {
              ...(qualification.rightOperand.type === 'VariableOperandRest'
                ? { value: qualification.rightOperand.value, valueType: 'long' }
                : betweenOperators.indexOf(operator) >= 0
                ? {
                    ...transformValueType({
                      type: qualification.rightOperand.value.type,
                      value: qualification.rightOperand.value.value[0],
                    }),
                    toValue: qualification.rightOperand.value.value[1],
                  }
                : transformValueType(qualification.rightOperand.value)),
            }
          : {}),
        // add left operand additional params for dependentField is true
        ...(qualification.leftOperand.dependentField
          ? { leftOperandAdditionalParams: { dependentField: true } }
          : {}),
        ...(qualification.type === 'FlotoSubQueryRest'
          ? {
              sourceModel: qualification.sourceModel,
              paramName: qualification.description,
            }
          : {}),
      },
    ]
  }
}

export function transformSearchCriteriasForServer(moduleName, searchCriterias) {
  const keywords = searchCriterias
    .filter(({ valueType }) => valueType === 'keyword')
    .map(({ value }) => value)
  const subQueries = searchCriterias.filter(
    ({ type }) => type === 'FlotoSubQueryRest'
  )
  return buildFlatQualificationStructure([
    ...searchCriterias
      .filter(({ valueType }) => valueType !== 'keyword')
      .filter(({ type }) => type !== 'FlotoSubQueryRest')
      .filter(
        ({ description }) => description !== 'hidden_search_value_selector'
      )
      .map((c) => transformSearchCriteriaForServer(moduleName, c)),
    ...(keywords.length ? [transformKeywordCriteriaForServer(keywords)] : []),
    ...(subQueries.length
      ? transformSubQueryCriteriaForServer(moduleName, subQueries)
      : []),
  ])
}

export function transformKeywordCriteriaForServer(keywords) {
  return {
    type: 'KeywordQualificationRest',
    keywordSet: keywords,
  }
}

export function transformSubQueryCriteriaForServer(moduleName, subQueries) {
  let qualificatins = []
  subQueries.forEach((s) => {
    qualificatins = qualificatins.concat(
      transformSubQueryForServer(moduleName, s)
    )
  })
  return qualificatins
}

export function transformSearchCriteriaForServer(moduleName, searchCriteria) {
  const operator = searchCriteria.operator.value
  if (unaryOperators.indexOf(operator) >= 0) {
    return {
      description: searchCriteria.description,
      qualContext: searchCriteria.qualContext,
      type: 'UnaryQualificationRest',
      leftOperand: buildLeftOperand(
        moduleName,
        searchCriteria.paramName,
        searchCriteria.paramType,
        // add left operand additional params for dependentField is true
        searchCriteria.leftOperandAdditionalParams
      ),
      operator: transformOperatorForServer(searchCriteria.operator.value),
    }
  }
  return {
    description: searchCriteria.description,
    qualContext: searchCriteria.qualContext,
    type: 'RelationalQualificationRest',
    leftOperand: buildLeftOperand(
      moduleName,
      searchCriteria.paramName,
      searchCriteria.paramType,
      // add left operand additional params for dependentField is true
      searchCriteria.leftOperandAdditionalParams
    ),
    operator: transformOperatorForServer(searchCriteria.operator.value),
    rightOperand: transfromRightOperandForServer(searchCriteria),
  }
}

function transfromRightOperandForServer(searchCriteria) {
  let valueType = searchCriteria.valueType
  if (fieldValueOperators.indexOf(searchCriteria.operator.value) >= 0) {
    valueType = 'field'
  }
  // if its variable operand
  if (valueType === 'long' && /^[a-z]+/.test(searchCriteria.value)) {
    return {
      type: 'VariableOperandRest',
      value: searchCriteria.value,
    }
  }
  return {
    type: 'ValueOperandRest',
    ...transformValueTypeForServer(
      valueType,
      betweenOperators.indexOf(searchCriteria.operator.value) >= 0
        ? [searchCriteria.value, searchCriteria.toValue]
        : searchCriteria.value
    ),
  }
}

function buildLeftOperand(
  moduleName,
  paramName,
  paramType = 'property',
  leftOperandAdditionalParams = {}
) {
  if (/^\d+$/.test(paramName)) {
    return {
      type: 'CustomFieldOperandRest',
      key: Number(paramName),
      // add left operand additional params for dependentField is true
      ...(leftOperandAdditionalParams || {}),
    }
  } else {
    return {
      type: OperandTypeMap[paramType],
      ...(paramType !== 'variable'
        ? {
            key: `${
              paramType === 'property' ? `${moduleName}.` : ''
            }${paramName}`,
          }
        : {}),
      ...(paramType === 'variable' ? { value: paramName } : {}),
    }
  }
}

export function transformSubQuery(moduleName, qualification) {
  const __t = getRootTranslator()
  if (qualification.description === 'subQuery|task|name') {
    const operator = transformOperator(qualification.operator)
    return {
      paramName: qualification.description,
      type: 'FlotoSubQueryRest',
      ...qualification,
      ...transformValueType(
        qualification.childQualification.rightOperand.value
      ),
      operator: {
        name: __t(operator),
        value: operator,
      },
    }
  }
}

function transformSubQueryForServer(moduleName, searchCriteria) {
  if (searchCriteria.paramName === 'subQuery|task|name') {
    return [
      buildSubQueryQualificationStructure(
        buildRelationalQualificationStructure(
          `${searchCriteria.sourceModel}.name`,
          searchCriteria.operator.value,
          searchCriteria.value
        ),
        'id',
        searchCriteria.sourceModel,
        'refId',
        searchCriteria.operator.value,
        'subQuery|task|name'
      ),
      {
        ...buildRelationalQualificationStructure(
          'refModel',
          'equal',
          searchCriteria.sourceModel,
          'string',
          'variable'
        ),
        description: 'hidden_search_value_selector',
      },
    ]
  }
  if (searchCriteria.paramName === 'subQuery|approval|name') {
    return [
      /** ** start subquesry 1 ***/
      buildSubQueryQualificationStructure(
        /** ** start subquesry 2 ***/
        buildSubQueryQualificationStructure(
          buildFlatQualificationStructure([
            /** ** start subquesry 3 ***/
            buildSubQueryQualificationStructure(
              buildRelationalQualificationStructure(
                `${searchCriteria.sourceModel}.name`,
                searchCriteria.operator.value,
                searchCriteria.value
              ),
              'id',
              searchCriteria.sourceModel,
              'refId'
            ),
            /** ** end subquesry 3 ***/
            buildRelationalQualificationStructure(
              `${moduleName}.refModel`,
              'in',
              [searchCriteria.sourceModel],
              'enum'
            ),
          ]),
          'id',
          'approval',
          'refId'
        ),
        /** ** end subquesry 2 ***/
        'id',
        'approval_stage',
        'refId'
      ),
      /** ** end subquesry 1 ***/
    ]
  }
  if (searchCriteria.paramName === 'subQuery|approval|displayName') {
    const fieldName =
      [
        Constants.PURCHASE,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_NON_IT,
      ].indexOf(searchCriteria.sourceModel) >= 0
        ? 'displayName'
        : 'subject'
    return [
      /** ** start subquesry 1 ***/
      buildSubQueryQualificationStructure(
        /** ** start subquesry 2 ***/
        buildSubQueryQualificationStructure(
          buildFlatQualificationStructure([
            /** ** start subquesry 3 ***/
            buildSubQueryQualificationStructure(
              buildRelationalQualificationStructure(
                `${searchCriteria.sourceModel}.${fieldName}`,
                searchCriteria.operator.value,
                searchCriteria.value
              ),
              'id',
              searchCriteria.sourceModel,
              'refId'
            ),
            /** ** end subquesry 3 ***/
            buildRelationalQualificationStructure(
              `${moduleName}.refModel`,
              'in',
              [searchCriteria.sourceModel],
              'enum'
            ),
          ]),
          'id',
          'approval',
          'refId'
        ),
        /** ** end subquesry 2 ***/
        'id',
        'approval_stage',
        'refId'
      ),
      /** ** end subquesry 1 ***/
    ]
  }
  if (searchCriteria.paramName === 'subQuery|approval|type') {
    return [
      /** ** start subquesry 1 ***/
      buildSubQueryQualificationStructure(
        /** ** start subquesry 2 ***/
        buildSubQueryQualificationStructure(
          buildRelationalQualificationStructure(
            `${moduleName}.refModel`,
            searchCriteria.operator.value,
            searchCriteria.value,
            'enum'
          ),
          'id',
          'approval',
          'refId'
        ),
        /** ** end subquesry 2 ***/
        'id',
        'approval_stage',
        'refId'
      ),
      /** ** end subquesry 1 ***/
    ]
  }
  if (searchCriteria.paramName === 'subQuery|approval|subject') {
    return [
      buildSubQueryQualificationStructure(
        buildRelationalQualificationStructure(
          'approval_stage.subject',
          searchCriteria.operator.value,
          searchCriteria.value
        ),
        'id',
        'approval_stage',
        'refId'
      ),
    ]
  }
  return null
}
