import Moment from 'moment'
import humanizeDuration from 'humanize-duration'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
import { getSavedState } from '@state/modules/auth'
import { LANGUAGE_MAP } from '@utils/language-map'

const __tc = getRootPluaralTranslator()

export default function duration(value, defaultValue, isHumanize) {
  if (value) {
    if (isHumanize) {
      return Moment.duration(Math.abs(value), 'milliseconds').humanize()
    }
    const suffix =
      defaultValue && Math.abs(defaultValue) < new Date().getTime()
        ? __tc('ago')
        : ''
    const prefix =
      defaultValue && Math.abs(defaultValue) > new Date().getTime()
        ? __tc('in')
        : ''
    const currentUser = getSavedState('auth.user')
    const language = LANGUAGE_MAP[(currentUser || {}).userLanguage]
    return `${prefix} ${humanizeDuration(value, {
      delimiter: ' ',
      largest: 3,
      round: true,
      ...(language ? { language } : {}),
      fallbacks: ['en'],
    })} ${suffix}`
    // const m = Moment.duration(Math.abs(value), 'milliseconds')
    // if (m.isValid(m)) {
    //   if (m.asSeconds() < 60) {
    //     return `${prefix} ${Math.ceil(m.seconds())} ${__tc(
    //       'seconds',
    //       m.seconds()
    //     )} ${suffix}`
    //   }
    //   if (m.asMinutes() < 60) {
    //     return `${prefix} ${Math.ceil(m.minutes())} ${__tc(
    //       'minutes',
    //       m.minutes()
    //     )} ${
    //       Math.ceil(m.seconds()) > 0
    //         ? `${Math.ceil(m.seconds())} ${__tc('seconds', m.seconds())}`
    //         : ''
    //     }  ${suffix}`
    //   }
    //   if (m.days() >= 1) {
    //     if (m.hours() <= 0) {
    //       return `${prefix} ${m.days()} ${__tc('days', m.days())} ${suffix}`
    //     }
    //     if (m.minutes() <= 0) {
    //       return `${prefix} ${m.days()} ${__tc('days', m.days())} ${Math.ceil(
    //         m.hours()
    //       )} ${__tc('hours', m.hours())} ${suffix}`
    //     }
    //     return `${prefix} ${m.days()} ${__tc('days', m.days())} ${Math.ceil(
    //       m.hours()
    //     )} ${__tc('hours', m.hours())} ${Math.ceil(m.minutes())} ${__tc(
    //       'minutes',
    //       m.minutes()
    //     )} ${suffix}`
    //   }
    //   return `${prefix} ${Math.ceil(m.hours())} ${__tc(
    //     'hours',
    //     m.hours()
    //   )} ${Math.ceil(m.minutes())} ${__tc('minutes', m.minutes())} ${suffix}`
    // }
  }
  return '---'
}
