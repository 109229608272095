import Moment from 'moment'
import { getSavedState } from '@state/modules/auth'
import { getCurrentOrganization } from '@utils/auth'

import(/* webpackChunkName: "utils" */ 'moment-timezone')

export default function datetime(
  value,
  format = 'ddd, MMM DD, YYYY hh:mm A',
  useTime = true
) {
  if (!value) {
    return ''
  }
  const organization = getCurrentOrganization()
  const currentUser = getSavedState('auth.user')
  const formatField = !useTime ? 'dateFormat' : 'dateTimeFormat'

  if ((organization || {})[formatField]) {
    format = (organization || {})[formatField]
  }
  if ((currentUser || {})[formatField]) {
    format = (currentUser || {})[formatField]
  }

  let timezone
  if (currentUser && currentUser.timezone) {
    timezone = currentUser.timezone
  }
  if (Moment.isMoment(value)) {
    if (timezone) {
      return value.tz(timezone).format(format)
    }
    return value.format(format)
  }
  if (/^-?\d+$/.test(value)) {
    if (timezone) {
      return Moment.unix(value / 1000)
        .tz(timezone)
        .format(format)
    }
    return Moment.unix(value / 1000).format(format)
  }
  return value
}
