export function transformGuidedTour(guidedTour) {
  return {
    id: guidedTour.id,
    setupCompleted: guidedTour.setupCompleted,
    setupCompletedSteps: guidedTour.setupCompletedSteps,
    setupCompletedSections: guidedTour.setupCompletedSections,
    setupCompletedGuides: guidedTour.setupCompletedGuides,
  }
}

export function transformGuidedTourForServer(guidedTour) {
  return {
    id: guidedTour.id,
    setupCompleted: guidedTour.setupCompleted,
    setupCompletedSteps: guidedTour.setupCompletedSteps,
    setupCompletedSections: guidedTour.setupCompletedSections,
    setupCompletedGuides: guidedTour.setupCompletedGuides,
  }
}
