export function transformChangeLog(changeLog) {
  return {
    text: changeLog.changeLogString,
    links: changeLog.links || [],
    createdAt: changeLog.createdTime,
    id: changeLog.id,
    owner: changeLog.createdById,
    actionType: changeLog.type,
    type: changeLog.refModel,
    origin: changeLog.origin,
    componentId: changeLog.refId,
    jsonDataId: changeLog.id,
  }
}
