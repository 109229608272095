export function transformIntegration(integration) {
  return {
    id: integration.id,
    name: integration.name,
    integrationType: integration.integrationType,
    description: integration.description,
    ...(integration.restApiIntegrationDetails &&
    integration.restApiIntegrationDetails.transientInfo
      ? {
          transientInfo: {
            clientId:
              integration.restApiIntegrationDetails.transientInfo.clientId,
            clientSecret:
              integration.restApiIntegrationDetails.transientInfo.clientSecret,
            basicAuth:
              integration.restApiIntegrationDetails.transientInfo.basicAuth,
          },
        }
      : {}),
    ...(integration.restApiIntegrationDetails
      ? {
          userId: integration.restApiIntegrationDetails.userId,
          accessLevel: integration.restApiIntegrationDetails.userId
            ? 'specific_user'
            : 'any_user',
        }
      : {}),
    enabled: !integration.disabled,
    canDelete: integration.deleteableOob,
    canEdit: integration.updatebleOob,
    proxyConfigId: integration.proxyConfigId,
  }
}

export function transformIntegrationForServer(integration) {
  return {
    name: integration.name,
    description: integration.description,
    integrationType: 'rest',
    restApiIntegrationDetails: {
      userId: integration.userId,
    },
    disabled: !integration.enabled,
    proxyConfigId: integration.proxyConfigId,
  }
}

export function transformAppIntegration(integration) {
  return {
    id: integration.id,
    name: integration.name,
    integrationType: integration.integrationType,
    type: integration.type,
    username: integration.username,
    domainUrl: integration.domainUrl,
    password: integration.password,
    description: integration.description,
    canDelete: integration.deleteableOob,
    canEdit: integration.updatebleOob,
    logoPath: integration.logoPath,
  }
}

export function transformAppIntegrationForServer(integration) {
  return {
    name: integration.name,
    description: integration.description,
    username: integration.username,
    domainUrl: integration.domainUrl,
    password: integration.password,
    integrationType: 'jira', // @TODO change dynamicaly in future for more type
    type: 'JiraAppIntegrationRest',
    logoPath: integration.logoPath,
  }
}

export function transformRequestIntegrationForList(integration) {
  return {
    integrationType: integration.integrationType,
    integrationId: integration.integrationId,
    jiraIssueKey: integration.jiraIssueKey,
    canDelete: integration.deleteableOob,
    canUpdate: integration.updatebleOob,
    browseUrl: integration.jiraIssueRest.browseUrl,
    issueType: integration.jiraIssueRest.issueType,
    priority: integration.jiraIssueRest.priority,
    projectKey: integration.jiraIssueRest.projectKey,
    subject: integration.jiraIssueRest.subject,
  }
}

export function transformRequestIntegration(integration) {
  return {
    browseUrl: integration.browseUrl,
    issueType: integration.issueType,
    jiraIssueKey: integration.key,
    projectKey: integration.projectKey,
    priority: integration.priority,
    subject: integration.subject,
    integrationType: integration.integrationType,
  }
}

export function transformJiraMetadata(jira) {
  return {
    id: jira.id,
    issueTypes: jira.issueTypes,
    priorities: jira.priorities,
    projects: jira.projects,
  }
}

export function transformJiraForServer(jira) {
  return {
    projectKey: jira.projectKey,
    priorityId: jira.priority,
    issueTypeId: jira.issueType,
  }
}
