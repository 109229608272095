import { transformRecursive } from '@data/recursive'
import { generateId } from '@utils/id'

export const dependentFieldOptionKeys = Object.keys(
  transformDependentFieldOptions({})
)

export function transformDependentFieldOptionsRecursive(options) {
  return transformRecursive(options, transformDependentFieldOptions)
}

export function transformDependentFieldOptions(option = {}, parentId) {
  return {
    id: option.id || generateId(),
    value: option.id,
    order: option.objOrder,
    name: option.name,
    parentId: option.parentId || parentId,
    fieldId: option.fieldId,
    children: [],
    canDelete: option.deleteableOob,
    archived: option.removed,
    canEdit: option.updatebleOob,
  }
}

export function transformDependentFieldOptionsForServer(option = {}) {
  const optionId = /^\d+$/.test(option.id) ? option.id : 0
  const parentId = /^\d+$/.test(option.parentId) ? option.parentId : 0
  const data = {
    id: optionId,
    objOrder: option.order,
    name: option.name,
    parentId,
    fieldId: option.fieldId,
    children: [],
  }
  return data
}
