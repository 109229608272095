import { getRootTranslator } from '@/src/utils/get-module-translator'

const __rootTc = getRootTranslator()

const map = {
  min_respond_level_1_violated: 0,
  level_1_violated: 1,
  level_2_violated: 2,
  level_3_violated: 3,
  level_4_violated: 4,
  level_5_violated: 5,
}

export function transformEscalationLevel(level) {
  if (level) {
    if (map[level]) {
      return __rootTc('escalation_level_value', { level: `${map[level]}` })
    }
  }
  return __rootTc('not_violated')
}

export const EscalationLevelOptions = () =>
  Object.keys(map)
    .filter((key) => map[key])
    .map((value) => ({
      text: __rootTc('escalation_level_value', { level: `${map[value]}` }),
      value,
      key: value,
    }))
