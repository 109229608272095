export function transformReleaseType(releaseType) {
  return {
    id: releaseType.id,
    name: releaseType.name,
    text: releaseType.name,
    key: releaseType.id,
    color: releaseType.colourCode,
    disabled: releaseType.inActive,
    oob: releaseType.oob,
    archived: releaseType.removed,
    canDelete: releaseType.deleteableOob,
    canEdit: releaseType.updatebleOob,
    default: releaseType.isDefault,
  }
}

export function transformReleaseTypeForServer(releaseType) {
  return {
    name: releaseType.name,
    colourCode: releaseType.color,
  }
}
