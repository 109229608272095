import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __tc = getRootPluaralTranslator()

export default function bytes(
  value,
  unit = 'gb',
  autoUnit = false,
  addSuffix = true
) {
  const v = parseInt(value)
  if (v && !isNaN(v)) {
    const suffix = addSuffix ? __tc(unit) : ''
    if (autoUnit === true) {
      const k = v / 1000
      const m = v / (1000 * 1000)
      const g = v / (1000 * 1000 * 1000)
      if (g > 1) {
        return `${g.toFixed(2)} ${__tc('gb')}`
      }
      if (m > 1) {
        return `${m.toFixed(2)} ${__tc('mb')}`
      }
      if (k > 1) {
        return `${k.toFixed(2)} ${__tc('kb')}`
      }
    }
    if (unit === 'gb') {
      return `${(v / (1024 * 1024 * 1024)).toFixed(2)} ${suffix}`
    }
    if (unit === 'mb') {
      return `${(v / (1024 * 1024)).toFixed(2)} ${suffix}`
    }
  }
  return '---'
}
