import { generateId } from '@utils/id'

export function transformRegistryDynamicVariable(item) {
  return {
    id: item.id,
    name: item.name,
    variableName: item.variableName,
    description: item.description,
  }
}

export function transformRegistryItem(item) {
  return {
    id: generateId(),
    registryTemplateId: item.registryTemplateId,
    itemAction: item.itemAction,
    headerKey: item.headerKey,
    subKey: item.subKey,
    dataType: item.dataType,
    valueName: item.valueName,
    valueData: item.valueData,
    description: item.description,
  }
}
export function transformRegistryItemForServer(item) {
  return {
    registryTemplateId: item.registryTemplateId,
    itemAction: item.itemAction,
    headerKey: item.headerKey,
    subKey: item.subKey,
    dataType: item.dataType,
    valueName: item.valueName,
    valueData: item.valueData,
    description: item.description,
  }
}
export function transformRegistryTemplate(registry) {
  return {
    id: registry.id,
    name: registry.name,
    description: registry.description,
    registryItems: (registry.registryItems || []).map(transformRegistryItem),
    canDelete: registry.deleteableOob,
    canEdit: registry.updatebleOob,
    createdAt: registry.createdTime,
  }
}

export function transformRegistryTemplateForServer(registry) {
  return {
    id: registry.id,
    name: registry.name,
    description: registry.description,
    registryItems: (registry.registryItems || []).map(
      transformRegistryItemForServer
    ),
  }
}
