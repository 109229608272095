export function transformSource(source) {
  return {
    id: source.id,
    name: source.name,
    text: source.name,
    key: source.id,
    disabled: source.inActive,
    systemName: (source.systemName || source.name).toLowerCase(),
    oob: source.oob,
    archived: source.removed,
    canDelete: source.deleteableOob,
    canEdit: source.updatebleOob,
    default: source.isDefault,
  }
}

export function transformSourceForServer(source) {
  return {
    name: source.name,
    isDefault: source.default ? source.default : undefined,
  }
}
