export function transformUrgency(urgency) {
  return {
    id: urgency.id,
    name: urgency.name,
    text: urgency.name,
    key: urgency.id,
    color: urgency.colourCode,
    archived: urgency.archived,
    createdById: urgency.createdById,
    disabled: urgency.inActive,
    oob: urgency.oob,
    default: urgency.isDefault,
    canDelete: urgency.deleteableOob,
    canEdit: urgency.updatebleOob,
  }
}

export function transformUrgencyForServer(urgency) {
  return {
    name: urgency.name,
    colourCode: urgency.color,
    isDefault: urgency.default ? urgency.default : undefined,
  }
}
