import { generateId } from '@utils/id'
export function transformAbandonedCall(abandonedCall) {
  return {
    id: generateId(),
    requesterName: abandonedCall.requesterName,
    requesterId: abandonedCall.requesterId,
    requesterNumber: abandonedCall.requesterNumber,
    lastCallTime: abandonedCall.lastCallTime,
    attempts: abandonedCall.attempts,
    abandonedReason: abandonedCall.abandonedReason,
    lastCallStatus: abandonedCall.lastCallStatus,
  }
}

export function transformCallReminder(callReminder) {
  return {
    id: callReminder.id,
    requesterId: callReminder.requesterId,
    requesterName: callReminder.requesterName,
    requesterNumber: callReminder.requesterNumber,
    callbackTime: callReminder.callbackTime,
    createdTime: callReminder.createdTime,
    notes: callReminder.notes,
    status: callReminder.status,
    canCall: callReminder.showCallIcon,
  }
}
export function transformCallReminderForServer(callReminder) {
  return {
    id: callReminder.id,
    requesterId: callReminder.requesterId,
    requesterName: callReminder.requesterName,
    requesterNumber: callReminder.requesterNumber,
    callbackTime: callReminder.callbackTime,
    createdTime: callReminder.createdTime,
    notes: callReminder.notes,
    status: callReminder.status,
  }
}
