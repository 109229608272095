export function transformSoftwareType(softwareType) {
  return {
    id: softwareType.id,
    name: softwareType.name,
    systemName: softwareType.systemName,
    description: softwareType.description,
    text: softwareType.name,
    key: softwareType.id,
    disabled: softwareType.inActive,
    oob: status.oob,
    canDelete: softwareType.deleteableOob,
    canEdit: softwareType.updatebleOob,
    default: softwareType.isDefault,
    archived: softwareType.removed,
  }
}

export function transformSoftwareTypeForServer(softwareType) {
  return {
    name: softwareType.name,
  }
}
