import SortBy from 'lodash/sortBy'
import { generateId } from '@utils/id'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
import {
  transformAvailableAction,
  transformActionForServer,
  transformAction,
} from './action'
import {
  transformAvailableQualification,
  transformQualificationForServer,
  transformQualification,
  dropdownTypeMap,
} from './qualification'
import Constant from '@constants'
import {
  transformField,
  buildInputTypeForField,
  getAdditionalFieldProps,
} from './form'

export function transformModuleNameForServer(moduleName) {
  if (moduleName === Constant.SERVICE_CATALOG) {
    return Constant.REQUEST
  }
  return moduleName
}

function transformAvailableTrigger(trigger) {
  let field
  if (trigger.field) {
    field = transformField(trigger.field)
  }
  return {
    label: trigger.triggerName,
    value: trigger.eventAttribute,
    transitional: trigger.transational,
    event: trigger.executionEvent,
    paramName: trigger.eventAttribute,
    isCustomField: trigger.customField,
    // inputType: trigger.inputTypes,
    inputType: dropdownTypeMap[trigger.inputTypes]
      ? 'dropdown'
      : trigger.inputTypes,
    ...(dropdownTypeMap[trigger.inputTypes]
      ? dropdownTypeMap[trigger.inputTypes]()
      : {}),
    ...(field
      ? {
          inputType: buildInputTypeForField(field),
          customFieldType: field.type,
          ...getAdditionalFieldProps(field),
        }
      : {}),
  }
}

export function transformSupportData(data) {
  return {
    // moduleName: data.refModel,
    triggers: (data.triggers || []).map(transformAvailableTrigger),
    qualifications: (data.qualifications || []).map(
      transformAvailableQualification
    ),
    actions: (data.actions || []).map(transformAvailableAction),
  }
}

export function transformTriggerForServer(trigger) {
  return {
    description: trigger.description,
    id: trigger.id,
    type:
      trigger.triggerType === 'event' ? 'EventTriggerRest' : 'TimeTriggerRest',
    ...(trigger.event ? { executionEvent: trigger.event } : {}),
    ...(trigger.trigger ? { eventAttribute: trigger.trigger } : {}),
    ...(trigger.interval ? { interval: trigger.interval } : {}),
    ...(trigger.unit ? { unit: trigger.unit } : {}),
    ...(trigger.transitionFrom !== undefined
      ? { transitionFrom: `${trigger.transitionFrom}` }
      : {}),
    ...(trigger.transitionTo !== undefined
      ? { transitionTo: `${trigger.transitionTo}` }
      : {}),
  }
}

function transformTransitionValue(value) {
  if (/^\d+$/.test(value)) {
    return parseInt(value, 10)
  }
  if (['true', 'false'].indexOf(value) >= 0) {
    return value === 'true'
  }
  return value
}

export function transformTrigger(trigger) {
  return {
    id: trigger.id,
    guid: generateId(),
    description: trigger.description,
    ...(trigger.executionEvent ? { event: trigger.executionEvent } : {}),
    ...(trigger.eventAttribute ? { trigger: trigger.eventAttribute } : {}),
    ...(trigger.interval ? { interval: trigger.interval } : {}),
    ...(trigger.unit ? { unit: trigger.unit } : {}),
    ...('transitionFrom' in trigger
      ? { transitionFrom: transformTransitionValue(trigger.transitionFrom) }
      : {}),
    ...('transitionTo' in trigger
      ? { transitionTo: transformTransitionValue(trigger.transitionTo) }
      : {}),
  }
}

export function transformWorkflowBlockForServer(block) {
  const transformedBlock = {}
  if (block.actions) {
    if (block.actions.positive) {
      transformedBlock.ifActionSet = block.actions.positive.actions.map(
        transformActionForServer
      )
    }
    if (block.actions.negative) {
      transformedBlock.elseActionSet = block.actions.negative.actions.map(
        transformActionForServer
      )
    }
  }
  if (block.qualifications) {
    transformedBlock.qualification = transformQualificationForServer(
      block.qualifications
    )
  }
  return transformedBlock
}

export function transformWorkflowBlock(block) {
  const transformedBlock = {
    guid: generateId(),
    type: 'condition',
  }
  if ((block.ifActionSet || []).length) {
    transformedBlock.actions = {
      positive: {
        guid: generateId(),
        type: 'action',
        actions: block.ifActionSet.map(transformAction),
      },
    }
  }
  if ((block.elseActionSet || []).length) {
    transformedBlock.actions = {
      ...(transformedBlock.actions || {}),
      negative: {
        guid: generateId(),
        type: 'action',
        actions: block.elseActionSet.map(transformAction),
      },
    }
  }
  if (block.qualification) {
    transformedBlock.qualifications = transformQualification(
      block.qualification
    )
  }
  return transformedBlock
}

export function transformWorkflowForServer(workflow) {
  const triggerType = workflow.type === 'periodic' ? 'time' : 'event'
  return {
    name: workflow.name,
    triggerType,
    trigger:
      triggerType === 'event'
        ? workflow.trigger.triggers.map((t) =>
            transformTriggerForServer({ ...t, triggerType })
          )
        : [
            {
              interval: workflow.trigger.interval,
              unit: workflow.trigger.unit,
              description:
                workflow.trigger.description ||
                workflow.trigger.triggers[0].description,
              ...(workflow.id ? { id: workflow.trigger.triggers[0].id } : {}),
            },
          ].map((t) => transformTriggerForServer({ ...t, triggerType })),
    blocks: workflow.workflowBlocks
      .map(transformWorkflowBlockForServer)
      .map((block, index) => ({ ...block, objOrder: index + 1 })),
  }
}

export function transformWorkflow(workflow) {
  const type = workflow.triggerType === 'time' ? 'periodic' : 'event'
  return {
    id: workflow.id,
    name: workflow.name,
    type,
    moduleName: workflow.refModel,
    ...(workflow.refId ? { parentId: workflow.refId } : {}),
    trigger: {
      guid: generateId(),
      type: 'trigger',
      triggers: (workflow.trigger || []).map(transformTrigger),
      ...(workflow.triggerType === 'time'
        ? {
            interval: workflow.trigger[0].interval,
            unit: workflow.trigger[0].unit,
          }
        : {}),
    },
    workflowBlocks: SortBy(workflow.blocks || [], 'objOrder').map(
      transformWorkflowBlock
    ),
  }
}

export function transformWorkflowForList(workflow) {
  return {
    name: workflow.name,
    moduleName: workflow.refModel,
    id: workflow.id,
    ...(workflow.refId ? { parentId: workflow.refId } : {}),
    description: workflow.description,
    type: workflow.triggerType === 'time' ? 'periodic' : 'event',
    enabled: !workflow.disabled,
    trigger: (workflow.trigger || []).map((t) => t.description),
    canUpdate: workflow.deleteableOob,
    canDelete: workflow.updatebleOob,
    createdAt: workflow.createdTime,
  }
}

function isActionsInvalid(block) {
  const __t = getRootPluaralTranslator()
  if (
    block.actions.positive &&
    (!block.actions.positive.actions.length ||
      !block.actions.positive.actions[0].action)
  ) {
    return {
      item: {
        ...block.actions.positive,
        type: 'action',
        blockGuid: block.guid,
        actionType: 'positive',
      },
      message: __t('workflowModule.action_is_required'),
    }
  }
  if (
    block.actions.negative &&
    (!block.actions.negative.actions.length ||
      !block.actions.negative.actions[0].action)
  ) {
    return {
      item: {
        ...block.actions.negative,
        actionType: 'negative',
        type: 'action',
        blockGuid: block.guid,
      },
      message: __t('workflowModule.action_is_required'),
    }
  }
  return false
}

export function isWorkflowInvalid(workflow) {
  const __t = getRootPluaralTranslator()
  // check trigger block first
  const trigger = workflow.trigger.triggers[0] || {}
  if (!trigger.event && !workflow.trigger.interval) {
    return {
      message: __t('workflowModule.trigger_is_required'),
      item: workflow.trigger,
    }
  }

  // check all other blocks
  let invalidBlock = false
  workflow.workflowBlocks.some((block) => {
    if (block.type === 'condition') {
      const q = transformQualificationForServer(block.qualifications)
      if (!q) {
        invalidBlock = {
          item: block,
          message: __t('workflowModule.condition_is_required'),
        }
        return true
      }
      if (
        !block.actions ||
        (!block.actions.positive && !block.actions.negative)
      ) {
        invalidBlock = {
          add_block_type: 'positive_action',
          add_block_for: block,
          message: __t('workflowModule.action_is_required_for_condition'),
        }
        return true
      }
      invalidBlock = isActionsInvalid(block)
      if (invalidBlock) {
        return true
      }
    } else {
      invalidBlock = isActionsInvalid(block)
      if (invalidBlock) {
        return true
      }
    }
  })
  return invalidBlock
}
