import Invert from 'lodash/invert'

export const availableOperators = {
  less_than: 'lessthan',
  less_than_or_equal: 'lessthanorequal',
  greater_than_or_equal: 'greaterthanorequal',
  greater_than: 'greaterthan',
  equal: 'equal',
  equal_case_insensitive: 'equal_case_insensitive',
  like: 'like',
  start_with: 'start_with',
  end_with: 'end_with',
  contains: 'contains',
  not_contains: 'not_contains',
  between: 'between',
  is_member: 'is_memeber',
  all_members_exist: 'all_members_exist',
  any_member_or_all_members_exist: 'any_member_or_all_members_exist',
  no_members_exist: 'no_members_exist',
  any_member_but_not_all_members_exist: 'any_member_but_not_all_members_exist',
  is_due_within: 'is_due_within',
  has_passed: 'has_passed',
  match_tree: 'match_tree',
  not_equal: 'not_equal',
  not_equal_case_insensitive: 'not_equal_case_insensitive',
  not_in: 'not_in',
  not_in_case_insensitive: 'not_in_case_insensitive',
  in: 'in',
  in_case_insensitive: 'in_case_insensitive',
  and: 'and',
  or: 'or',
  not: 'not',
  is_null: 'is_null',
  is_not_null: 'is_not_null',
  before: 'before',
  after: 'after',
  not_any_members_exist: 'not_any_members_exist',
  is_blank: 'is_blank',
  is_not_blank: 'is_not_blank',
  is_empty: 'is_empty',
  is_not_empty: 'is_not_empty',
  exactly_next: 'exactly_next',
  exactly_last: 'exactly_last',
  within_next: 'within_next',
  within_last: 'within_last',
  between_next: 'between_next',
  between_last: 'between_last',
  same_as: 'same_as',
  not_same_as: 'not_same_as',
}
export function transformOperator(operator) {
  let operatorsMap = Invert(availableOperators)
  if (operatorsMap[operator]) {
    return operatorsMap[operator]
  }
  throw new Error(`${operator} Operator not found`)
}

export function transformOperatorForServer(operator) {
  if (availableOperators[operator]) {
    return availableOperators[operator]
  }
  throw new Error(`${operator} Operator not found`)
}

export const unaryOperators = [
  'is_blank',
  'is_not_blank',
  'is_empty',
  'is_not_empty',
  'is_null',
  'is_not_null',
]

export const currentDateSupportedOperators = [
  'equal',
  'greater_than',
  'less_than',
]

export const durationOperators = [
  'before',
  'after',
  'exactly_next',
  'exactly_last',
  'within_next',
  'within_last',
  'between_next',
  'between_last',
]
export const betweenOperators = ['between', 'between_next', 'between_last']

export const onlyDaysdurationOperators = ['exactly_next', 'exactly_last']

export const onlyDateTimeDurationOperators = [
  'within_next',
  'within_last',
  'between_next',
  'between_last',
]

export const fieldValueOperators = ['same_as', 'not_same_as']
