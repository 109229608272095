export function transformRemoteOffice(remoteOffice) {
  return {
    id: remoteOffice.id,
    name: remoteOffice.name,
    description: remoteOffice.description,
    communicationType: remoteOffice.communicationType,
    host: remoteOffice.host,
    port: remoteOffice.port,
    computerIds: remoteOffice.computerIds,
    updatebleOob: remoteOffice.updatebleOob,
    deleteableOob: remoteOffice.deleteableOob,
    proxyConfigId: remoteOffice.proxyConfigId,
  }
}
export function transformRemoteOfficeForServer(remoteOffice) {
  return {
    name: remoteOffice.name,
    description: remoteOffice.description,
    communicationType: remoteOffice.communicationType,
    host: remoteOffice.host,
    port: remoteOffice.port,
    computerIds: remoteOffice.computerIds,
    updatebleOob: remoteOffice.updatebleOob,
    deleteableOob: remoteOffice.deleteableOob,
    proxyConfigId: remoteOffice.proxyConfigId,
  }
}
