import { generateId } from '@utils/id'
export function transformTelephony(telephony) {
  return {
    id: generateId(),
    username: telephony.username,
    password: telephony.password,
    enabled: telephony.enabled,
    userIds: telephony.userIds,
    webUrl: telephony.webUrl,
    apiUrl: telephony.apiUrl,
    apiKey: telephony.apiKey,
  }
}

export function transformTelephonyForServer(telephony) {
  if (!telephony.enabled) {
    return {
      enabled: telephony.enabled,
    }
  }
  return {
    username: telephony.username,
    password: telephony.password,
    enabled: telephony.enabled,
    userIds: telephony.userIds,
    webUrl: telephony.webUrl,
    apiUrl: telephony.apiUrl,
    apiKey: telephony.apiKey,
  }
}

//  for telephony team memner
export function transformCoOpsMember(member) {
  return {
    id: member.userId,
    name: member.name,
    email: member.email,
    role: member.role,
    skills: member.skills,
    extensionType: member.extensionType,
    extensionNumber: member.extensionNumber,
    userId: member.userId,
    contactNo: member.contactNo,
    altContactNo1: member.altContactNo1,
    altContactNo2: member.altContactNo2,
  }
}

export function transformCallHistoryForList(history) {
  return {
    id: generateId(),
    callId: history.callId,
    requesterNumber: history.requesterNumber,
    requesterId: history.requesterId,
    requesterName: history.requesterName,
    callType: history.callType,
    status: history.status,
    duration: history.duration,
    actionType: history.actionType,
    actionNumber: history.actionNumber,
    callNote: history.callNote,
    callTime: history.callTime,
    showRecordingIcon: history.showRecordingIcon,
  }
}

export function transformKpi(kpi, value) {
  return {
    id: kpi.id,
    name: kpi.name,
    value: value,
    userIds: kpi.userIds,
    groupIds: kpi.groupIds,
    description: kpi.description,
    serverQualifications: kpi.qual,
    dateFilter: kpi.dateFilterProp,
    moduleName: kpi.model,
    orginalModuleName: kpi.orginalModel,
  }
}

export function transformCoOpsMemberForServer(member) {
  return {
    name: member.name,
    email: member.email,
    role: member.role,
    skills: member.skills,
    extensionType: member.extensionType,
    extensionNumber: member.extensionNumber,
    userId: member.userId,
    contactNo: member.contactNo,
    altContactNo1: member.altContactNo1,
    altContactNo2: member.altContactNo2,
  }
}
// recent-call for user-profile
export function transformRecentCall(recentCall) {
  return {
    id: generateId(),
    requesterNumber: recentCall.requesterNumber,
    lastCallTime: recentCall.lastCallTime,
    callTime: recentCall.callTime,
    callType: recentCall.callType,
    status: recentCall.status,
    assignee: recentCall.assignee,
    duration: recentCall.duration,
  }
}

export function transformActiveAgentForList(agent) {
  return {
    ...agent,
    id: agent.userId,
    name: agent.name,
    status: agent.status,
    durationInStatus: agent.durationInStatus,
    userId: agent.userId,
  }
}

export function transformWaitingCallForList(call) {
  return {
    ...call,
    id: call.requesterNumber,
    requesterNumber: call.requesterNumber,
    requesterId: call.requesterId,
    waitTime: call.waitTime,
  }
}

export function transformOnHoldCallForList(call) {
  return {
    ...call,
    id: call.requesterNumber,
    requesterId: call.requesterId,
    requesterNumber: call.requesterNumber,
    talkTime: call.talkTime,
    holdTime: call.holdTime,
  }
}

export function transformAgents(agent) {
  return {
    id: generateId(),
    name: agent.name,
    status: agent.status,
    durationInStatus: status.durationInStatus,
  }
}
