import { generateId } from '@utils/id'
import { buildImageUrlFromRefFileName } from '@data/attachment'
export function transformSsoConfig(ssoConfig) {
  return {
    id: ssoConfig.id,
    name: ssoConfig.name,
    ssoEnabled: ssoConfig.ssoEnabled,
    // isPrimary: ssoConfig.isDefault,
    ssoProvider: ssoConfig.ssoProvider,
    loginButtonText: ssoConfig.loginButtonText,
    enforceSsoLoginOnly: ssoConfig.enforceSsoLoginOnly,
    autoCreateUser: ssoConfig.autoCreateUser,
    excludedTechnincians: ssoConfig.excludedTechnincians,
    idpEntityId: ssoConfig.idpEntityId,
    idpLoginUrl: ssoConfig.idpLoginUrl,
    idpLogoutUrl: ssoConfig.idpLogoutUrl,
    idpFingerprint: ssoConfig.idpFingerprint,
    spEntityId: ssoConfig.spEntityId,
    spAssertionUrl: ssoConfig.spAssertionUrl,
    spSingleLogoutUrl: ssoConfig.spSingleLogoutUrl,
    attachments: ssoConfig.attachments,
    spPublicKeyFiles: ssoConfig.spPublicKeyFiles,
    spPrivateKeyFiles: ssoConfig.spPrivateKeyFiles,
    canDelete: ssoConfig.deleteableOob,
    canEdit: ssoConfig.updatebleOob,
    userInputRequired: ssoConfig.userInputRequired,
    isMspPortal: ssoConfig.mspPortal,
    archived: ssoConfig.removed,
    attributes: (ssoConfig.attributes || []).map((m) => ({
      ...m,
      guid: generateId(),
    })),
    ...(ssoConfig.ssoIcon && (ssoConfig.ssoIcon || {}).refFileName
      ? {
          ssoIcon: [ssoConfig.ssoIcon],
          ssoIconUrl:
            buildImageUrlFromRefFileName(
              (ssoConfig.ssoIcon || {}).refFileName
            ) || '',
        }
      : {}),
  }
}

export function transformSsoConfigForServer(ssoConfig) {
  return {
    name: ssoConfig.name,
    ssoEnabled: ssoConfig.ssoEnabled,
    ssoProvider: !ssoConfig.ssoProvider ? null : ssoConfig.ssoProvider,
    loginButtonText: ssoConfig.loginButtonText,
    // isDefault:
    //   'ssoEnabled' in ssoConfig && !ssoConfig.ssoEnabled
    //     ? false
    //     : ssoConfig.isPrimary,
    // enforceSsoLoginOnly: ssoConfig.enforceSsoLoginOnly,
    autoCreateUser: ssoConfig.autoCreateUser,
    // excludedTechnincians: ssoConfig.excludedTechnincians,
    idpEntityId: ssoConfig.idpEntityId,
    idpLoginUrl: ssoConfig.idpLoginUrl,
    idpLogoutUrl: ssoConfig.idpLogoutUrl,
    idpFingerprint: ssoConfig.idpFingerprint,
    spEntityId: ssoConfig.spEntityId,
    spAssertionUrl: ssoConfig.spAssertionUrl,
    spSingleLogoutUrl: ssoConfig.spSingleLogoutUrl,
    attachments: ssoConfig.attachments,
    spPublicKeyFiles: ssoConfig.spPublicKeyFiles,
    spPrivateKeyFiles: ssoConfig.spPrivateKeyFiles,
    attributes: ssoConfig.attributes,
    ssoIcon: (ssoConfig.ssoIcon || [])[0] || {},
  }
}

export function transformVuexSsoConfig(ssoConfig) {
  return {
    ssoEnabled: ssoConfig.ssoEnabled,
    ssoProvider: ssoConfig.ssoProvider,
    enforceSsoLoginOnly: ssoConfig.enforceSSOEnabled,
    excludeTechnicianEnabled: ssoConfig.excludeTechnicianConfigured,
    ssoConfigs: (ssoConfig.ssoConfigs || []).map(transformSsoConfig),
  }
}
