export function transformImpact(impact) {
  return {
    id: impact.id,
    name: impact.name,
    text: impact.name,
    key: impact.id,
    color: impact.colourCode,
    archived: impact.archived,
    createdById: impact.createdById,
    disabled: impact.inActive,
    oob: impact.oob,
    default: impact.isDefault,
    canDelete: impact.deleteableOob,
    canEdit: impact.updatebleOob,
  }
}

export function transformImpactForServer(impact) {
  return {
    name: impact.name,
    colourCode: impact.color,
    isDefault: impact.default ? impact.default : undefined,
  }
}
