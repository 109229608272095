export function transformSoftwareLicenseType(type) {
  return {
    id: type.id,
    name: type.name,
    systemName: type.systemName,
    description: type.description,
    text: type.name,
    ignoreVersion: type.ignoreVersion,
    key: type.id,
    disabled: type.inActive,
    oob: status.oob,
    canDelete: type.deleteableOob,
    canEdit: type.updatebleOob,
    default: type.isDefault,
  }
}

export function transformSoftwareLicenseTypeForServer(type) {
  return {
    name: type.name,
    ignoreVersion: type.ignoreVersion,
  }
}
