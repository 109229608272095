export function transformCurrency(currency) {
  return {
    id: currency.id,
    name: currency.name,
    text: currency.name,
    key: currency.id,
    currencyFullName: currency.currencyFullName,
    enabled: currency.enabled,
  }
}
