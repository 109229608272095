export function transformPrintTemplate(template) {
  return {
    id: template.id,
    name: template.name,
    content: template.printFormat || '',
    primary: template.isDefault,
    enabled: template.enabled,
    moduleName: template.model,
    description: template.description,
    canDelete: template.deleteableOob,
    canEdit: template.updatebleOob,
    parentId: template.refId,
  }
}

export function transformPrintTemplateForServer(template) {
  return {
    name: template.name,
    printFormat: template.content || '',
    isDefault: template.primary,
    enabled: template.enabled,
    description: template.description,
  }
}
