import Constants from '@constants'

import { transformTicketForServer } from './ticket'
export const transformTemplateForList = (template) => ({
  id: template.id,
  name: template.name,
  templateDescription: template.templateDescription,
  subject: template.subject,
  departmentId: template.departmentId,
})

export const transformTemplateForServer = (moduleName, template) => {
  if (
    moduleName === Constants.REQUEST ||
    moduleName === Constants.PROBLEM ||
    moduleName === Constants.CHANGE ||
    moduleName === Constants.RELEASE
  ) {
    return transformTicketForServer(template)
  }
  return { ...template }
}

export const transformTemplate = (template) => ({
  ...template,
  ...(template.fieldValueDetails || {}),
  knownError: template.knownError ? 'true' : 'false',
  companyId: template.companyId,
})
