import Vue from 'vue'
import dateTime from './datetime'
import timeago from './timeago'
import duration from './duration'
import monthName from './month-name'
import dayName from './day-name'
import bytes from './bytes'
import dayTime from './daytime'

Vue.filter('datetime', dateTime)
Vue.filter('timeago', timeago)
Vue.filter('duration', duration)
Vue.filter('monthName', monthName)
Vue.filter('dayName', dayName)
Vue.filter('bytes', bytes)
Vue.filter('daytime', dayTime)
