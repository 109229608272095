import SortBy from 'lodash/sortBy'
import Omit from 'lodash/omit'

export function transformRecursive(
  collection,
  transformFn,
  childKey = 'childrens',
  orderingKey = 'objOrder',
  dataKey = 'node',
  parentId = 0,
  childKeyForServer = 'children'
) {
  const transformedColletion = []
  for (let i = 0; i < collection.length; i++) {
    const c = transformFn(
      dataKey ? collection[i][dataKey] : collection[i],
      parentId
    )
    if (collection[i][childKey]) {
      c[childKeyForServer] = transformRecursive(
        SortBy(
          collection[i][childKey],
          dataKey ? `${dataKey}.${orderingKey}` : orderingKey
        ),
        transformFn,
        childKey,
        orderingKey,
        dataKey,
        c.id,
        childKeyForServer
      )
    }
    transformedColletion.push(c)
  }
  return transformedColletion
}

export function transformRecursiveForBuildTree(
  collection,
  transformFn,
  childKey = 'children',
  orderingKey = 'objOrder',
  dataKey = 'node'
) {
  const transformedColletion = []
  for (let i = 0; i < collection.length; i++) {
    let c = {}
    if (collection[i][childKey]) {
      c.childrens = transformRecursiveForBuildTree(
        SortBy(collection[i][childKey], `${dataKey}.${orderingKey}`),
        transformFn,
        childKey,
        orderingKey,
        dataKey
      )
    }
    const node = {}
    Object.keys(collection[i])
      .filter((f) => f !== childKey)
      .forEach((k) => {
        node[k] = collection[i][k]
      })
    c = {
      ...c,
      [dataKey]: transformFn(node),
    }
    transformedColletion.push(c)
  }
  return transformedColletion
}

export function findValuePath(
  collection,
  value,
  childKey = 'children',
  key = 'id'
) {
  const currentPath = []
  let found = false

  function search(haystack) {
    for (let i = 0; i < haystack.length; i++) {
      currentPath.push(haystack[i][key])
      if (haystack[i][key] === value) {
        found = true
        break
      }
      if (
        haystack[i][childKey] &&
        Array.isArray(haystack[i][childKey]) &&
        haystack[i][childKey].length
      ) {
        search(haystack[i][childKey])
        if (found) break
      }
      currentPath.pop()
    }
  }

  search(collection)
  return currentPath
}

export function findValuePathWithItems(
  collection,
  value,
  childKey = 'children',
  key = 'id'
) {
  const currentPath = []
  let found = false

  function search(haystack) {
    for (let i = 0; i < haystack.length; i++) {
      currentPath.push(haystack[i])
      if (haystack[i][key] === value) {
        found = true
        break
      }
      if (
        haystack[i][childKey] &&
        Array.isArray(haystack[i][childKey]) &&
        haystack[i][childKey].length
      ) {
        search(haystack[i][childKey])
        if (found) break
      }
      currentPath.pop()
    }
  }

  search(collection)
  return currentPath
}

export function findValueObject(
  collection,
  value,
  childKey = 'children',
  key = 'id'
) {
  let foundItem
  function search(haystack) {
    for (let i = 0; i < haystack.length; i++) {
      if (haystack[i][key] === value) {
        foundItem = haystack[i]
        break
      }
      if (
        haystack[i][childKey] &&
        Array.isArray(haystack[i][childKey]) &&
        haystack[i][childKey].length
      ) {
        search(haystack[i][childKey])
        if (foundItem) break
      }
    }
  }
  search(collection)
  return foundItem
}

export function flattenRecursive(collection, childKey = 'children', arr = []) {
  for (let i = 0; i < collection.length; i++) {
    arr.push(Omit(collection[i], [childKey]))
    if (collection[i][childKey]) {
      flattenRecursive(collection[i][childKey], childKey, arr)
    }
  }
  return arr
}

export function buildHierarchy(
  collection,
  childKey = 'children',
  key = 'id',
  parentKeyName = 'parentId'
) {
  const topLevelItems = collection.filter((c) => !c[parentKeyName])
  const attachChildren = (item) => {
    return {
      ...item,
      [childKey]: collection
        .filter((c) => c[parentKeyName] === item[key])
        .map(attachChildren),
    }
  }
  return topLevelItems.map(attachChildren)
}

export function collapseAll(collection, childKey = 'children') {
  return collection.map(function collapse(item) {
    return {
      ...item,
      expanded: false,
      ...(item[childKey] ? { [childKey]: item[childKey].map(collapse) } : {}),
    }
  })
}

export function expandeSelected(
  value,
  collection,
  key = 'id',
  childKey = 'children'
) {
  return collection.map(function expand(item) {
    const flatten = flattenRecursive([item], childKey)
    const selectedChildren = flatten.filter((i) =>
      Array.isArray(value) ? value.indexOf(i[key]) >= 0 : value === i[key]
    )
    return {
      ...item,
      expanded: selectedChildren.length
        ? true
        : Array.isArray(value)
        ? value.indexOf(item[key]) >= 0
        : value === item[key],
      ...(item[childKey] ? { [childKey]: item[childKey].map(expand) } : {}),
    }
  })
}

export function searchRecursive(
  searchTerm,
  collection,
  childKey = 'children',
  searchKey = 'name'
) {
  return collection
    .map(function search(item) {
      const children =
        item[childKey] && item[childKey].length
          ? searchRecursive(searchTerm, item[childKey], childKey, searchKey)
          : false
      if (
        (children && children.length) ||
        item[searchKey]
          .toLowerCase()
          .indexOf((searchTerm || '').toLowerCase()) >= 0
      ) {
        const expandedChildren = (item[childKey] || []).map(function search(
          innerItem
        ) {
          const innerChildren = (innerItem[childKey] || []).map(search)
          const expanded = !!innerChildren.find((c) => c.expanded)
          return {
            ...innerItem,
            [childKey]: innerChildren,
            expanded:
              expanded ||
              innerItem[searchKey]
                .toLowerCase()
                .indexOf((searchTerm || '').toLowerCase()) >= 0,
          }
        })
        return {
          ...item,
          expanded: true,
          [childKey]: expandedChildren,
        }
      }
      return false
    })
    .filter(Boolean)
}
// export function searchRecursive(
//   searchTerm,
//   collection,
//   childKey = 'children',
//   searchKey = 'name'
// ) {
//   return collection
//     .map(function search(item) {
//       const children =
//         item[childKey] && item[childKey].length
//           ? searchRecursive(searchTerm, item[childKey], childKey, searchKey)
//           : false
//       if (
//         (children && children.length) ||
//         item[searchKey]
//           .toLowerCase()
//           .indexOf((searchTerm || '').toLowerCase()) >= 0
//       ) {
//         return {
//           ...item,
//           expanded:
//             !!(children || []).length,
//           [childKey]: (children || []).length ? children : item[childKey],
//         }
//       }
//       return false
//     })
//     .filter(Boolean)
// }

export function removeItemFromList(
  item,
  collection,
  key = 'id',
  childKey = 'children'
) {
  return collection
    .map(function updatedItem(loopItem) {
      if (loopItem[key] === item[key]) {
        return null
      }
      return {
        ...loopItem,
        ...(loopItem[childKey]
          ? {
              [childKey]: loopItem[childKey].map(updatedItem).filter(Boolean),
            }
          : {}),
      }
    })
    .filter(Boolean)
}

export function changeParentForItem(
  item,
  parentId,
  collection,
  parentKey = 'parentId',
  key = 'id',
  childKey = 'children'
) {
  const removedCollection = removeItemFromList(item, collection, key, childKey)
  if (!parentId) {
    return [...removedCollection, { ...item, [parentKey]: undefined }]
  }
  return removedCollection.map(function checkCollection(loopItem) {
    return {
      ...loopItem,
      ...(loopItem[key] === parentId ? { expanded: true } : {}),
      ...(loopItem[childKey]
        ? {
            [childKey]:
              loopItem[key] === parentId
                ? [...loopItem[childKey], { ...item, [parentKey]: parentId }]
                : loopItem[childKey].map(checkCollection),
          }
        : {}),
    }
  })
}

export function getDepth(obj, childKey = 'childrens') {
  let depth = 1
  const getDepthCount = (obj) => {
    if ((obj[childKey] || []).length) {
      depth++
      return obj[childKey].forEach(getDepthCount)
    }
  }
  getDepthCount(obj)
  return depth
}
