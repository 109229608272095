export function transformRiskType(riskType) {
  return {
    id: riskType.id,
    name: riskType.name,
    text: riskType.name,
    key: riskType.id,
    color: riskType.colourCode,
    disabled: riskType.inActive,
    oob: riskType.oob,
    archived: riskType.removed,
    canDelete: riskType.deleteableOob,
    canEdit: riskType.updatebleOob,
    default: riskType.isDefault,
  }
}

export function transformRiskTypeForServer(riskType) {
  return {
    name: riskType.name,
    colourCode: riskType.color,
  }
}
export function transformProjectType(projectType) {
  return {
    id: projectType.id,
    name: projectType.name,
    text: projectType.name,
    key: projectType.id,
    color: projectType.colourCode,
    disabled: projectType.inActive,
    oob: projectType.oob,
    archived: projectType.removed,
    canDelete: projectType.deleteableOob,
    canEdit: projectType.updatebleOob,
    default: projectType.isDefault,
  }
}
export function transformProjectTypeForServer(projectType) {
  return {
    name: projectType.name,
    colourCode: projectType.color,
  }
}
