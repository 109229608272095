export function transformStatus(status) {
  return {
    id: status.id,
    name: status.name,
    supportPortalStatusName: status.supportPortalStatusName,
    systemName: status.systemName,
    text: status.name,
    key: status.id,
    color: status.colourCode,
    archived: status.removed,
    createdById: status.createdById,
    disabled: status.inActive,
    oob: status.oob,
    runSla: !status.slaOff,
    runUc: !status.ucOff,
    canDelete: status.deleteableOob,
    canEdit: status.updatebleOob,
    default: status.isDefault,
    stage: status.statusStage,
    order: status.objOrder,
  }
}
export function transformStatusForServer(status) {
  return {
    name: status.name,
    colourCode: status.color,
    ...(status.supportPortalStatusName
      ? { supportPortalStatusName: status.supportPortalStatusName }
      : {}),
    ...('default' in status
      ? { isDefault: status.default ? status.default : undefined }
      : {}),
    ...('stage' in status ? { statusStage: status.stage } : {}),
    ...('runSla' in status ? { slaOff: !status.runSla } : {}),
    ...('runUc' in status ? { ucOff: !status.runUc } : {}),
  }
}
