export function transformLeaveType(leaveType) {
  return {
    id: leaveType.id,
    name: leaveType.name,
    color: leaveType.colourCode,
    enabled: !leaveType.disabled,
    description: leaveType.description,
    canDelete: leaveType.deleteableOob,
    canEdit: leaveType.updatebleOob,
  }
}

export function transformLeaveTypeForServer(leaveType) {
  return {
    name: leaveType.name,
    colourCode: leaveType.color,
    disabled: !leaveType.enabled,
    description: leaveType.description,
  }
}
