import { generateId } from '@utils/id'
import {
  transformBreakTimeForServer,
  transformBreakTime,
} from './business-hour'

export function transformDeploymentPolicies(deploymentPolicy) {
  return {
    id: deploymentPolicy.id,
    name: deploymentPolicy.name,
    description: deploymentPolicy.description,
    deploymentDays: deploymentPolicy.deploymentDays,
    startHour: deploymentPolicy.startHour,
    startMin: deploymentPolicy.startMin,
    endHour: deploymentPolicy.endHour,
    endMin: deploymentPolicy.endMin,
    initiatePolicy: deploymentPolicy.initiatePolicy,
    rebootPolicy: deploymentPolicy.rebootPolicy,
    skipRebootPlolicyIfNotRequired:
      deploymentPolicy.skipRebootPlolicyIfNotRequired,
    showNotification: deploymentPolicy.showNotification,
    stopRebootOrShutDownWhileInstalling:
      deploymentPolicy.stopRebootOrShutDownWhileInstalling,
    enableRebootNotification: deploymentPolicy.enableRebootNotification,
    allowUserToPostpone: deploymentPolicy.allowUserToPostpone,
    timeFrameSet: deploymentPolicy.timeFrameSet.map((i) => ({
      ...i,
      guid: generateId(),
    })),
    rebootNotificationTitle: deploymentPolicy.rebootNotificationTitle,
    rebootNotificationMessage: deploymentPolicy.rebootNotificationMessage,
    notificationTitle: deploymentPolicy.notificationTitle,
    notificationMessage: deploymentPolicy.notificationMessage,
    allowToSkipDeployment: deploymentPolicy.allowToSkipDeployment,
    forceDeploymentDays: deploymentPolicy.forceDeploymentDays,
    ...transformBreakTime(deploymentPolicy),
  }
}

export function transformDeploymentPoliciesForServer(deploymentPolicy) {
  return {
    name: deploymentPolicy.name,
    description: deploymentPolicy.description,
    deploymentDays: deploymentPolicy.deploymentDays,
    startHour: deploymentPolicy.startHour,
    startMin: deploymentPolicy.startMin,
    endHour: deploymentPolicy.endHour,
    endMin: deploymentPolicy.endMin,
    initiatePolicy: deploymentPolicy.initiatePolicy,
    rebootPolicy: deploymentPolicy.rebootPolicy,
    skipRebootPlolicyIfNotRequired:
      deploymentPolicy.skipRebootPlolicyIfNotRequired,
    showNotification: deploymentPolicy.showNotification,
    stopRebootOrShutDownWhileInstalling:
      deploymentPolicy.stopRebootOrShutDownWhileInstalling,
    enableRebootNotification: deploymentPolicy.enableRebootNotification,
    rebootNotificationTitle: deploymentPolicy.rebootNotificationTitle,
    rebootNotificationMessage: deploymentPolicy.rebootNotificationMessage,
    notificationTitle: deploymentPolicy.notificationTitle,
    notificationMessage: deploymentPolicy.notificationMessage,
    allowToSkipDeployment: deploymentPolicy.allowToSkipDeployment,
    forceDeploymentDays: deploymentPolicy.forceDeploymentDays,
    allowUserToPostpone: deploymentPolicy.allowUserToPostpone,
    timeFrameSet: deploymentPolicy.timeFrameSet,
    ...transformBreakTimeForServer(deploymentPolicy),
  }
}
