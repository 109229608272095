export function transformSoftwareLicense(license) {
  return {
    id: license.id,
    name: license.name,
    displayName: license.displayName,
    productId: license.productId,
    licenceTypeId: license.licenceTypeId,
    softwareAssetId: license.softwareAssetId,
    allocatedAssetIds: license.allocatedAssetIds,
    allocationCount: license.allocationCount,
    licenseKeys: license.licenseKeys,
    purchaseCount: license.purchaseCount,
    purchaseDate: license.purchaseDate,
    expiryDate: license.expiryDate,
    ignoreVersion: license.ignoreVersion,
    cost: license.cost,
    currencyId: license.currencyId,
    version: license.version,
    description: license.description,
    totalInstallation: license.totalInstallation,
    userAllocationCount: license.userAllocationCount,
    userIds: license.userIds,
    licenseAttachment: license.licenseAttachment || [],
    invoiceDate: license.invoiceDate,
    invoiceAttachment: license.invoiceAttachment || [],
    purchaseOrderDate: license.purchaseOrderDate,
    purchaseOrderAttachment: license.purchaseOrderAttachment || [],
    underUtilizationLimit: license.underUtilizationLimit,
    overUtilizationLimit: license.overUtilizationLimit,
    compliance: license.compliance,
    disabled: license.inActive,
    archived: license.removed,
    createdBy: license.createdById,
    createdAt: license.createdTime,
    canDelete: license.deleteableOob,
    canEdit: license.updatebleOob,
  }
}

export function transformSoftwareLicenseForServer(license) {
  const transformedProps = []
  const obj = {}
  Object.keys(license).forEach((paramName) => {
    if (!transformedProps.includes(paramName)) {
      obj[paramName] = license[paramName]
    }
  })
  if ('description' in license) {
    obj.description = license.description || ''
  }
  return obj
}
