export function transformRiskType(riskType) {
  return {
    id: riskType.id,
    name: riskType.name,
    text: riskType.name,
    key: riskType.id,
    color: riskType.colourCode,
    disabled: riskType.inActive,
    oob: riskType.oob,
    archived: riskType.removed,
    canDelete: riskType.deleteableOob,
    canEdit: riskType.updatebleOob,
    default: riskType.isDefault,
  }
}

export function transformRiskTypeForServer(riskType) {
  return {
    name: riskType.name,
    colourCode: riskType.color,
  }
}
