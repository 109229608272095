export function transformAudit(audit) {
  return {
    text: audit.auditString,
    links: audit.links || [],
    createdAt: audit.createdTime,
    id: audit.id,
    owner: audit.createdById,
  }
}

export function transformTransition(transition) {
  return {
    id: transition.id,
    transitionType: transition.transitionType,
    transitionFieldId: transition.transitionFieldId,
    performerId: transition.performerId,
    oldTransitionFieldId: transition.oldTransitionFieldId,
    duration: transition.duration,
    createdAt: transition.createdTime,
    newTransitionFieldName: transition.newTransitionFieldName,
    oldTransitionFieldName: transition.oldTransitionFieldName,
    performerName: transition.performerName,
  }
}
