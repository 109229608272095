export function transformBarcode(barcode) {
  return {
    id: barcode.id,
    autoGenerationStrategy: barcode.autoGenerationStrategy,
    startedFrom: barcode.startedFrom,
    prefix: barcode.prefix,
    currentValue: barcode.currentValue,
    printFrom: barcode.printFrom,
    printTo: barcode.printTo,
    barcodeData: barcode.barcodeData,
  }
}

export function transformBarcodeForServer(barcode) {
  return {
    id: barcode.id,
    autoGenerationStrategy: barcode.autoGenerationStrategy,
    startedFrom: barcode.startedFrom,
    prefix: barcode.prefix,
    currentValue: barcode.currentValue,
    printFrom: Number(barcode.printFrom),
    printTo: Number(barcode.printTo),
    barcodeData: barcode.barcodeData,
  }
}
export function transformBarcodeConfigurationForServer(barcodeConfiguration) {
  return {
    id: barcodeConfiguration.id,
    width: barcodeConfiguration.width,
    height: barcodeConfiguration.height,
    printerResolution: barcodeConfiguration.printerResolution,
    labelsPerRow: barcodeConfiguration.labelsPerRow,
    gap: barcodeConfiguration.gap,
    barcodeCustomfieldKeys: barcodeConfiguration.barcodeCustomfieldKeys,
    barcodeMandatoryPropKeys: barcodeConfiguration.barcodeMandatoryPropKeys,
  }
}
export function transformBarcodeConfiguration(barcodeConfiguration) {
  return {
    width: barcodeConfiguration.width,
    height: barcodeConfiguration.height,
    printerResolution: barcodeConfiguration.printerResolution,
    labelsPerRow: barcodeConfiguration.labelsPerRow,
    gap: barcodeConfiguration.gap,
  }
}
