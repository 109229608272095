import { generateId } from '@utils/id'

import SortBy from 'lodash/sortBy'
export function transformPrePostInstallationAction(prePostInstallationAction) {
  return {
    guid: generateId(),
    id: prePostInstallationAction.id,
    files: prePostInstallationAction.files
      ? [prePostInstallationAction.files]
      : [],
    sharedDriveFilePath: prePostInstallationAction.sharedDriveFilePath,
    commandOrder: prePostInstallationAction.commandOrder,
    commandWithArguments: prePostInstallationAction.commandWithArguments,
    exitCode: prePostInstallationAction.exitCode,
    procceedInstallation: prePostInstallationAction.procceedInstallation,
    deployAction: prePostInstallationAction.deployAction,
  }
}

export function transformPrePostInstallationActionForServer(
  prePostInstallationAction
) {
  return {
    id: prePostInstallationAction.id,
    files: prePostInstallationAction.files
      ? prePostInstallationAction.files[0]
      : null,
    sharedDriveFilePath: prePostInstallationAction.sharedDriveFilePath,
    commandOrder: prePostInstallationAction.commandOrder,
    commandWithArguments: prePostInstallationAction.commandWithArguments,
    exitCode: prePostInstallationAction.exitCode,
    procceedInstallation: prePostInstallationAction.procceedInstallation,
    deployAction: prePostInstallationAction.deployAction,
  }
}

export function transformInstallationForServer(installationAction) {
  return {
    installationAction: {
      mspFileName: installationAction.mspFileName,
      // mstFileName: installationAction.mstFileName,
      mspArguments: installationAction.mspArguments,
      commandWithArguments: installationAction.commandWithArguments,
      preInstallationAction:
        (installationAction.preInstallationAction || []).map(
          transformPrePostInstallationActionForServer
        ) || [],
      postInstallationAction:
        (installationAction.postInstallationAction || []).map(
          transformPrePostInstallationActionForServer
        ) || [],
    },
  }
}

export function transformInstallation(installationAction) {
  return {
    installationAction: {
      mspFileName: installationAction.mspFileName,
      // mstFileName: installationAction.mstFileName,
      mspArguments: installationAction.mspArguments,
      commandWithArguments: installationAction.commandWithArguments,
      preInstallationAction: SortBy(
        (installationAction.preInstallationAction || []).map(
          transformPrePostInstallationAction
        ) || [],
        'commandOrder'
      ),
      postInstallationAction: SortBy(
        (installationAction.postInstallationAction || []).map(
          transformPrePostInstallationAction
        ) || [],
        'commandOrder'
      ),
    },
  }
}

export function transformUninstallationForServer(unInstallationAction) {
  return {
    unInstallationAction: {
      mspFileName: unInstallationAction.mspFileName,
      // mstFileName: unInstallationAction.mstFileName,
      mspArguments: unInstallationAction.mspArguments,
      commandWithArguments: unInstallationAction.commandWithArguments,
      unInstallScriptFile: unInstallationAction.unInstallScriptFile,
    },
  }
}
export function transformPackages(p) {
  return {
    id: p.id,
    name: p.name,
    displayName: p.displayName,
    subject: p.subject,
    description: p.description,
    version: p.version,
    osArchitecture: p.osArchitecture,
    osPlatform: p.osPlatform,
    vendorId: p.vendorId,
    fileType: p.fileType,
    fileLocation: p.fileLocation,
    files: p.files,
    sharedDriveFilePathSet: p.sharedDriveFilePathSet,
    canDelete: p.deleteableOob,
    canEdit: p.updatebleOob,
    createdAt: p.createdTime,
    configName: p.configName,
    ...transformInstallation(p.installationAction),
    ...transformUninstallationForServer(p.unInstallationAction),
  }
}

export function transformPackagesForServer(p) {
  return {
    id: p.id,
    name: p.name,
    displayName: p.displayName,
    subject: p.subject,
    description: p.description,
    version: p.version,
    osArchitecture: p.osArchitecture,
    osPlatform: p.osPlatform,
    vendorId: p.vendorId,
    fileType: p.fileType,
    fileLocation: p.fileLocation,
    files: p.files,
    sharedDriveFilePathSet: p.sharedDriveFilePathSet,
    ...transformInstallationForServer(p.installationAction),
    ...transformUninstallationForServer(p.unInstallationAction),
  }
}
