const greetUser = () => {
  let Figlet
  let AnsiShadow
  import('figlet')
    .then((d) => {
      Figlet = d.default || d
      return import('figlet/importable-fonts/Block.js')
    })
    .then((d) => {
      AnsiShadow = d.default || d
      Figlet.parseFont('Ansi Shadow', AnsiShadow)

      Figlet(
        'MOTADATA',
        {
          font: 'Ansi Shadow',
        },
        function(err, data) {
          if (err) {
            // eslint-disable-next-line
            console.log('Something went wrong...', err)
          } else {
            // eslint-disable-next-line
            console.log('%c ' + data, 'color: #099DD9;')
          }
        }
      )
    })
}

greetUser()
