import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __tc = getRootPluaralTranslator()

export default function hertz(value, unit = 'ghz') {
  const v = parseInt(value)
  if (v && !isNaN(v)) {
    const suffix = __tc(unit)
    if (unit === 'ghz') {
      return `${(v / 1000000000).toFixed(2)} ${suffix}`
    }
    if (unit === 'mhz') {
      return `${(v / 1000000).toFixed(2)} ${suffix}`
    }
  }
  return '---'
}
