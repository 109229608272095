export function transformWolHistory(history) {
  return {
    id: history.id,
    createdTime: history.createdTime,
    executionSource: history.executionSource,
    wolStatus: history.wolStatus,
    remark: history.remark,
    updatedTime: history.updatedTime,
  }
}
