export function transformResponseTemplate(template) {
  return {
    id: template.id,
    name: template.name,
    content: template.content,
    fileAttachments: template.fileAttachments,
    canDelete: template.deleteableOob,
    canEdit: template.updatebleOob,
    createdBy: template.createdById,
    createdAt: template.createdTime,
    updatedBy: template.updatedById,
    updatedAt: template.updatedTime,
    enabled: !template.disabled,
  }
}

export function transformResponseTemplateForServer(template) {
  return {
    name: template.name,
    content: template.content,
    fileAttachments: template.fileAttachments,
    disabled: !template.enabled,
  }
}
