export function transformWorkLog(worklog) {
  return {
    id: worklog.id,
    technicianId: worklog.technicianId,
    description: worklog.description || '',
    timeRange: {
      startTime: worklog.startTime,
      endTime: worklog.endTime,
    },
    createdAt: worklog.createdTime,
    timeBasedWorkLog: worklog.timeBasedWorkLog,
    workLogState: worklog.workLogState,
    timeTaken: worklog.timeTaken,
  }
}

export function transformWorkLogForServer(worklog) {
  return {
    id: worklog.id,
    technicianId: worklog.technicianId,
    description: worklog.description,
    timeBasedWorkLog: worklog.timeBasedWorkLog,
    ...(worklog.timeBasedWorkLog
      ? { workLogState: worklog.workLogState }
      : {
          startTime: worklog.timeRange.startTime,
          endTime: worklog.timeRange.endTime,
        }),
  }
}
