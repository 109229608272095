import {
  tranformFlotoScheduleForServer,
  transformFlotoSchedule,
} from '@data/report'
import { generateId } from '@utils/id'
import {
  transformQualificationForServer,
  transformQualification,
} from '@data/qualification'

export const NUMBER_TYPE_ATTRIBUTES = [
  'locationId',
  'assignedDepartmentId',
  'productId',
  'vendorId',
  'companyId',
  'ComputerPropertyGroup.osManufacturer',
  'ComputerPropertyGroup.memorySize',
  'ComputerPropertyGroup.diskSize',
  'ComputerPropertyGroup.cpuSpeed',
  'ComputerPropertyGroup.cpuCoreCount',
  'ComputerPropertyGroup.numberOfLogicalProcessors',
  'ComputerPropertyGroup.numberOfProcessors',
  'ComputerSystemComponent.manufacturerId',
  'ComputerSystemComponent.numberOfLogicalProcessors',
  'ComputerSystemComponent.numberOfLogicalProcessors',
  'OsComponent.manufacturerId',
  'OsComponent.installedDate',
  'BiosComponent.manufacturerId',
  'BiosComponent.releaseDate',
  'RamComponent.manufacturerId',
  'RamComponent.size',
  'RamComponent.width',
  'RamComponent.clockSpeed',
  'ProcessorComponent.manufacturerId',
  'ProcessorComponent.width',
  'ProcessorComponent.cpuSpeed',
  'ProcessorComponent.coreCount',
  'ProcessorComponent.extClock',
  'ProcessorComponent.l1CacheSize',
  'ProcessorComponent.l2CacheSize',
  'ProcessorComponent.l3CacheSize',
  'NetworkAdapterComponent.manufacturerId',
  'NetworkAdapterComponent.dhcpLeaseObtained',
  'NetworkAdapterComponent.dhcpLeaseExpires',
]

export const BOOLEAN_TYPE_ATTRIBUTES = [
  'ComputerPropertyGroup.partOfDomain',
  'ComputerSystemComponent.partOfDomain',
  'NetworkAdapterComponent.dhcpEnable',
]

export function transformRuleValue(value, key) {
  if (NUMBER_TYPE_ATTRIBUTES.indexOf(key) >= 0) {
    return +value
  }
  if (BOOLEAN_TYPE_ATTRIBUTES.indexOf(key) >= 0) {
    return value === 'true'
  }
  return value
}

export function transformRules(rule) {
  return {
    guid: generateId(),
    value: transformRuleValue(rule.value, rule.propKey),
    key: rule.propKey,
  }
}
export function transformRulesForServer(rule) {
  return {
    value: `${rule.value}`,
    propKey: rule.key,
  }
}
export function transformBaseline(baseline) {
  return {
    id: baseline.id,
    name: baseline.name,
    displayName: baseline.displayName,
    description: baseline.description,
    assetTypeId: baseline.assetTypeId,
    status: baseline.status,
    execludeAssetIds: baseline.execludeAssetIds,
    technicianIds: baseline.technicianIds,
    publishedDate: baseline.publishedDate,
    underChangeControl: baseline.underChangeControl,
    varianceDetected: baseline.varianceDetected,
    totalAssociatedAssetCount: baseline.totalAssociatedAssetCount,
    enableAutomation: baseline.enableAutomation,
    qualifications: transformQualification(
      baseline.qualification || { groups: [], operators: [] }
    ),
    rules: baseline.rules.map(transformRules),
    schedule: baseline.schedule,
    createdById: baseline.createdById,
    createdAt: baseline.createdTime,
    updatedAt: baseline.updatedTime,
    canDelete: baseline.deleteableOob,
    canEdit: baseline.updatebleOob,
  }
}

export function transformBaselineForServer(baseline) {
  return {
    id: baseline.id,
    name: baseline.name,
    displayName: baseline.displayName,
    description: baseline.description,
    assetTypeId: baseline.assetTypeId,
    status: baseline.status,
    execludeAssetIds: baseline.execludeAssetIds,
    technicianIds: baseline.technicianIds,
    publishedDate: baseline.publishedDate,
    underChangeControl: baseline.underChangeControl,
    enableAutomation: baseline.enableAutomation,
    qualification: transformQualificationForServer(baseline.qualifications),
    varianceDetected: baseline.varianceDetected,
    totalAssociatedAssetCount: baseline.totalAssociatedAssetCount,
    rules: baseline.rules.map(transformRulesForServer),
    schedule: baseline.schedule,
    createdAt: baseline.createdTime,
    updatedAt: baseline.updatedTime,
  }
}
export function transformScheduleForServer(schedule) {
  return {
    flotoSchedule: tranformFlotoScheduleForServer(schedule),
    enableSchedule: schedule.enableSchedule,
  }
}

export function transformSchedule(schedule) {
  return {
    ...transformFlotoSchedule(schedule),
    enableSchedule: schedule.enableSchedule,
  }
}

export function transformAvailableColumn(c) {
  return {
    text: c.displayName,
    key: c.propKey,
    groupName: c.groupName,
  }
}
