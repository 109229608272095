export function transformNotification(notification) {
  const linkData =
    [
      'my_approval_is_requested',
      'my_approval_is_ignored',
      'approval_reminder',
    ].indexOf(notification.webNotificationType) >= 0
      ? 'approverData'
      : 'objectData'
  return {
    id: notification.id,
    performerId: notification.performerId,
    performerType: notification.performerType,
    createdTime: notification.createdTime,
    title: notification.title,
    read: notification.read,
    metadata: notification.metadata,
    refModel: notification.refModel,
    eventType: notification.eventType,
    webNotificationType: notification.webNotificationType,
    type:
      [
        'my_approval_is_requested',
        'my_approval_is_ignored',
        'approval_reminder',
      ].indexOf(notification.webNotificationType) >= 0
        ? 'approval'
        : 'regular',
    linkText: ((notification.metadata || {}).objectData || {}).name,
    linkId: ((notification.metadata || {})[linkData] || {}).id,
    ...(((notification.metadata || {}).objectData || {}).id
      ? {
          links: [
            {
              text: (notification.metadata.objectData || {}).name,
              id: (notification.metadata[linkData] || {}).id,
              model: notification.refModel,
              type:
                [
                  'my_approval_is_requested',
                  'my_approval_is_ignored',
                  'approval_reminder',
                ].indexOf(notification.webNotificationType) >= 0
                  ? 'approval'
                  : 'regular',
            },
          ],
        }
      : {}),
  }
}
export function transformNotificationForServer(notification) {
  return {
    performerId: notification.performerId,
    performerType: notification.performerType,
    createdTime: notification.createdTime,
    title: notification.title,
    read: !notification.read,
    metadata: notification.metadata,
    refModel: notification.refModel,
  }
}
