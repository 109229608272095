export function transformProductVendor(data) {
  return {
    id: data.id,
    name: data.name,
    productName: data.productName,
    productDisplayName: data.productDisplayName,
    manufacturerName: data.manufacturerName,
    manufacturerDisplayName: data.manufacturerDisplayName,
    productTypeName: data.productTypeName,
    vendorId: data.vendorId,
    productId: data.productId,
    price: data.price,
    maintenanceVendorId: data.maintenanceVendorId,
    description: data.description,
    year: data.year,
    month: data.month,
    canDelete: data.deleteableOob,
    canEdit: data.updatebleOob,
    currencyId: data.currencyId,
    taxRate: data.taxRate,
  }
}

export function transformProductVendorForServer(data) {
  return {
    id: data.id,
    name: data.name,
    vendorId: data.vendorId,
    productId: data.productId,
    price: data.price,
    maintenanceVendorId: data.maintenanceVendorId,
    description: data.description,
    year: data.year,
    month: data.month,
    currencyId: data.currencyId,
    taxRate: data.taxRate,
  }
}
