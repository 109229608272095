import SortBy from 'lodash/sortBy'
import Capitalize from 'lodash/capitalize'
import Moment from 'moment'
import { generateId } from '@utils/id'
import { getCurrentUser } from '@utils/auth'

function userTimeFormat() {
  return (getCurrentUser() || {}).timeFormat || 'hh:mm:A'
}

export function transformBreakTimeForServer(breakTime) {
  const startTime = Moment(breakTime.fromTime, userTimeFormat()).locale('en')
  const startHour = parseInt(startTime.format('HH'), 10)
  const startMin = parseInt(startTime.format('mm'), 10)
  const endTime = Moment(breakTime.toTime, userTimeFormat()).locale('en')
  const endHour = parseInt(endTime.format('HH'), 10)
  const endMin = parseInt(endTime.format('mm'), 10)
  return {
    ...(breakTime.id ? { id: breakTime.id } : {}),
    startHour,
    startMin,
    endHour,
    endMin,
  }
}

export function transformBreakTime(breakTime) {
  const fromTime = Moment(
    `${breakTime.startHour}:${breakTime.startMin}`,
    'k:m'
  ).format(userTimeFormat())
  const toTime = Moment(
    `${breakTime.endHour}:${breakTime.endMin}`,
    'k:m'
  ).format(userTimeFormat())
  return {
    ...(breakTime.id ? { id: breakTime.id } : {}),
    fromTime,
    toTime,
    guid: generateId(),
  }
}

function transformWeekDayTimesForServer(weekDayTimes) {
  const enabledWeekTimes = weekDayTimes.filter((w) => w.enabled)
  return enabledWeekTimes.map((weekTime) => {
    const startTime = Moment(weekTime.fromTime, userTimeFormat()).locale('en')
    const startHour = parseInt(startTime.format('HH'), 10)
    const startMin = parseInt(startTime.format('mm'), 10)
    const endTime = Moment(weekTime.toTime, userTimeFormat()).locale('en')
    const endHour = parseInt(endTime.format('HH'), 10)
    const endMin = parseInt(endTime.format('mm'), 10)
    const o = {
      ...(weekTime.id ? { id: weekTime.id } : {}),
      startHour,
      startMin,
      endHour,
      endMin,
      dayOfWeek: weekTime.day.toUpperCase(),
      breakTimes: (weekTime.breakTimes || []).map(transformBreakTimeForServer),
    }
    return o
  })
}

function transformWeekDayTimes(weekDayTimes) {
  return weekDayTimes.map((weekTime) => {
    const fromTime = Moment(
      `${weekTime.startHour}:${weekTime.startMin}`,
      'k:m'
    ).format(userTimeFormat())
    const toTime = Moment(
      `${weekTime.endHour}:${weekTime.endMin}`,
      'k:m'
    ).format(userTimeFormat())
    return {
      id: weekTime.id,
      enabled: true,
      fromTime,
      toTime,
      day: Capitalize(weekTime.dayOfWeek),
      breakTimes: (weekTime.breakTimes || []).map(transformBreakTime),
    }
  })
}

function transformHolidaysForServer(holidays) {
  return holidays.map((holiday) => ({
    ...(holiday.id ? { id: holiday.id } : {}),
    name: holiday.name,
    month: holiday.month,
    day: holiday.day,
  }))
}

function transformHolidays(holidays) {
  return holidays.map((holiday) => ({
    ...(holiday.id ? { id: holiday.id } : {}),
    guid: generateId(),
    name: holiday.name,
    month: holiday.month,
    day: holiday.day,
  }))
}

function transformExcludedTimes(leaves) {
  return leaves.map((leave) => ({
    ...(leave.id ? { id: leave.id } : {}),
    guid: generateId(),
    name: leave.excludedTimeName,
    dayNumber: leave.weekOfMonth,
    day: Capitalize(leave.dayOfWeek),
  }))
}

function transformExcludedTimesForServer(leaves) {
  return leaves.map((leave) => ({
    ...(leave.id ? { id: leave.id } : {}),
    excludedTimeName: leave.name,
    weekOfMonth: leave.dayNumber,
    dayOfWeek: leave.day.toUpperCase(),
  }))
}

export function transformBusinessHourForServer(businessHour) {
  return {
    name: businessHour.name,
    description: businessHour.description,
    whType: businessHour.workingHourType,
    weekHoursList: transformWeekDayTimesForServer(
      businessHour.weekDayTimes || []
    ),
    excludedTimes: transformExcludedTimesForServer(
      businessHour.excludedTimes || []
    ),
    holidays: transformHolidaysForServer(businessHour.holidays),
    timeZone: businessHour.timezone,
  }
}

export function transformBusinessHour(businessHour) {
  return {
    id: businessHour.id,
    name: businessHour.name,
    description: businessHour.description,
    workingHourType: businessHour.whType,
    weekDayTimes: transformWeekDayTimes(businessHour.weekHoursList || []),
    excludedTimes: transformExcludedTimes(businessHour.excludedTimes || []),
    holidays: SortBy(transformHolidays(businessHour.holidays || []), (x) =>
      Moment(`${x.month}-${x.day}`, 'M-D')
    ),
    timezone: businessHour.timeZone,
    canEdit: businessHour.updatebleOob,
  }
}

export function transformBusinessHourForList(businessHour) {
  return {
    id: businessHour.id,
    name: businessHour.name,
    workingHourType: businessHour.whType,
    description: businessHour.description,
    canDelete: businessHour.deleteableOob,
    enabled: !businessHour.disabled,
    timezone: businessHour.timeZone,
  }
}
