import { buildImageUrlFromRefFileName } from './attachment'

export function transformServiceCatalogForServer(service) {
  return {
    name: service.name,
    serviceCatalogStatus: service.status,
    details: service.serviceDescription,
    categoryId: service.categoryId,
    currencyId: service.currencyId,
    requesterGroupIds: service.requesterGroups,
    companyIds: service.companyIds,
    subject: service.subject,
    description: service.description,
    amount: service.amount,
    showInCustomerPortalAmount: service.showInCustomerPortalAmount,
    serviceCatalogImage: service.imageRef ? service.imageRef : null,
    allowRequesterToLinkMyAsset: service.allowRequesterToLinkMyAsset,
    allowRequesterToLinkMyCi: service.allowRequesterToLinkMyCi,
    customRuleEnable: service.customRuleEnable,
    readPermission: service.readPermission,
  }
}

export function transformServiceCatalog(service) {
  return {
    id: service.id,
    name: service.name,
    status: service.serviceCatalogStatus,
    serviceDescription: service.details,
    categoryId: service.categoryId,
    currencyId: service.currencyId,
    requesterGroups: service.requesterGroupIds,
    companyIds: service.companyIds,
    subject: service.subject,
    description: service.description,
    amount: service.amount,
    showInCustomerPortalAmount: service.showInCustomerPortalAmount,
    archived: service.removed,
    imageRef: (service.serviceCatalogImage || {}).refFileName
      ? service.serviceCatalogImage
      : null,
    image: (service.serviceCatalogImage || {}).refFileName
      ? buildImageUrlFromRefFileName(service.serviceCatalogImage.refFileName)
      : '/service-placeholder.png',
    allowRequesterToLinkMyAsset: service.allowRequesterToLinkMyAsset,
    allowRequesterToLinkMyCi: service.allowRequesterToLinkMyCi,
    customRuleEnable: service.customRuleEnable,
    readPermission: service.readPermission,
  }
}
