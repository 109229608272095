import Constants from '@constants'
import Invert from 'lodash/invert'
import Difference from 'lodash/difference'
import Moment from 'moment'
import store from '@state/store'
import CloneDeep from 'lodash/cloneDeep'
import { getCurrentUser, isPortalLogin } from '@utils/auth'
import { findValuePath } from '@data/recursive'
import IntersectionWith from 'lodash/intersectionWith'
import IsEqual from 'lodash/isEqual'
import { actionOptions, formRulesTabMap } from '@data/form-rules'
import { clearValueForCustomField } from './value-type'

export const groupNameMap = {
  request_fields: 'request',
  change_fields: 'change',
  requester_fields: 'user',
  logged_in_user_fields: 'logged_in_user',
  global_fields: 'global',
}

export function getFinalResult(list, operator) {
  if (operator === 'or') {
    let boolean = false
    list.forEach((v) => {
      boolean = boolean || v
    })
    return boolean
  } else if (operator === 'and') {
    let boolean = true
    list.forEach((v) => {
      boolean = boolean && v
    })
    return boolean
  }
  return true
}

export const fieldValueParamName = [
  'ReferencedSystemField.1',
  'ReferencedSystemField.2',
  'ReferencedSystemField.3',
  'ReferencedSystemField.4',
  'ReferencedSystemField.5',
  'ReferencedSystemField.6',
  'ReferencedSystemField.7',
  'ReferencedSystemField.8',
  'ReferencedSystemField.9',
  'ReferencedSystemField.10',
  'ReferencedSystemField.11',
  'ReferencedSystemField.12',
  'ReferencedSystemField.13',
  'ReferencedSystemField.14',
  'ReferencedSystemField.15',
  'ReferencedSystemField.16',
  'ReferencedSystemField.17',
  'ReferencedSystemField.18',
  'ReferencedSystemField.19',
]

export const ReferencedSystemFieldValue = (
  paramName,
  resource,
  requesterDetails
) => {
  switch (paramName) {
    case 'ReferencedSystemField.1':
      return requesterDetails.locationId
    case 'ReferencedSystemField.2':
      return requesterDetails.departmentId
    case 'ReferencedSystemField.3':
      return resource.technicianId
    case 'ReferencedSystemField.4':
      return resource.groupId
    case 'ReferencedSystemField.5':
      return resource.locationId
    case 'ReferencedSystemField.6':
      return resource.departmentId
    case 'ReferencedSystemField.7':
      return requesterDetails.managerId
    case 'ReferencedSystemField.8':
      return requesterDetails.id
    case 'ReferencedSystemField.9':
      return resource.changeReviewer
    case 'ReferencedSystemField.10':
      return resource.changeImplementor
    case 'ReferencedSystemField.11':
      return resource.changeManager
    case 'ReferencedSystemField.12':
      return resource.releaseEngineer
    case 'ReferencedSystemField.13':
      return resource.releaseManager
    case 'ReferencedSystemField.14':
      return resource.qaManager
    case 'ReferencedSystemField.15':
      return resource.releaseReviewer
    // following enum value represent asset module only
    // case 'ReferencedSystemField.16':
    //   return resource.managedById
    // case 'ReferencedSystemField.17':
    //   return resource.managedByGroupId
    // case 'ReferencedSystemField.18':
    //   return resource.assignedUserId
    case 'ReferencedSystemField.19':
      return resource.categoryId
    default:
      return undefined
  }
}
// get paramName
export function getParamName(operand) {
  if (operand.operandType !== 'property') {
    return operand.qualification
  }
  const list = operand.qualification.split('.')
  return list[list.length - 1]
}

// get set resource value for none value qualification execution
export function setResourceFieldValueForNoneQualificaionExecution(valueType) {
  switch (valueType) {
    case 'bigString':
    case 'string':
    case 'enum':
      return ''
    case 'boolean':
      return false
    case 'integer':
    case 'double':
      return 0
    case 'listLong':
    case 'listInteger':
      return [0]
    case 'listString':
    case 'listEnum':
      return ['']
    default:
      return undefined
  }
}

// get resourceValue
export function getResourceValue(operand, resource, requesterDetails) {
  let resourceDetails = resource
  if (operand.qualContext && groupNameMap[operand.qualContext] === 'user') {
    resourceDetails = requesterDetails
  } else if (
    operand.qualContext &&
    groupNameMap[operand.qualContext] === 'logged_in_user'
  ) {
    const currentUser = getCurrentUser()
    resourceDetails = {
      ...(currentUser || {}),
      ...(currentUser.fieldValueDetails
        ? { ...currentUser.fieldValueDetails }
        : {}),
      ...(isPortalLogin()
        ? { groups: (currentUser.requesterGroupId || []).concat([0]) }
        : {}),
    }
  } else if (
    operand.qualContext &&
    groupNameMap[operand.qualContext] === 'global'
  ) {
    resourceDetails = {
      supportPortal: window.location.origin,
    }
  }
  const paramName = getParamName(operand)
  if (operand.operandType === 'variable') {
    if (
      (paramName === 'departmentId' ||
        paramName === `${groupNameMap[operand.qualContext]}.departmentId`) &&
      resourceDetails.departmentId
    ) {
      const departmentOptions = store.getters['department/departmentOptions']
      return (
        findValuePath(departmentOptions, resourceDetails.departmentId) ||
        setResourceFieldValueForNoneQualificaionExecution(operand.valueType)
      )
    }
    if (
      (paramName === 'locationId' ||
        paramName === `${groupNameMap[operand.qualContext]}.locationId`) &&
      resourceDetails.locationId
    ) {
      const locationOptions = store.getters['location/locationOptions']
      return (
        findValuePath(locationOptions, resourceDetails.locationId) ||
        setResourceFieldValueForNoneQualificaionExecution(operand.valueType)
      )
    }
    if (
      (paramName === 'categoryId' ||
        paramName === `${groupNameMap[operand.qualContext]}.categoryId`) &&
      resourceDetails.categoryId
    ) {
      const categoryOptions =
        store.getters[
          `category/${groupNameMap[operand.qualContext]}/categories`
        ]
      return (
        findValuePath(categoryOptions, resourceDetails.categoryId) ||
        setResourceFieldValueForNoneQualificaionExecution(operand.valueType)
      )
    }
  }
  return (
    resourceDetails[paramName] ||
    setResourceFieldValueForNoneQualificaionExecution(operand.valueType)
  )
}

// check operand is applicable
export function executeOperand(operand, resource, requesterDetails) {
  const operator = operand.operator
  const value = operand.value
  const toValue = operand.toValue
  const resourceValue = getResourceValue(operand, resource, requesterDetails)
  // curret date qualifire
  if (
    value === Constants.CURRENT_DATE_VALUE ||
    value === Constants.CURRENT_DATE_TODAY
  ) {
    if (!resourceValue) {
      return false
    }
    const valueMoment = Moment(Moment().valueOf())
    const resourceValueMoment = Moment(resourceValue)
    if (operator === 'equal') {
      return resourceValueMoment.isSame(valueMoment, 'date')
    }
    if (operator === 'greater_than') {
      return resourceValueMoment.isAfter(valueMoment, 'date')
    }
    if (operator === 'less_than') {
      return resourceValueMoment.isBefore(valueMoment, 'date')
    }
  }
  if (operator === 'in') {
    if (Array.isArray(resourceValue)) {
      const isAvailable = IntersectionWith(value, resourceValue, IsEqual)
      return !!isAvailable.length
    }
    return value.indexOf(resourceValue) >= 0
  }
  if (operator === 'not_in') {
    if (Array.isArray(resourceValue)) {
      const isAvailable = IntersectionWith(value, resourceValue, IsEqual)
      return !isAvailable.length
    }
    return value.indexOf(resourceValue) === -1
  }
  if (operator === 'greater_than') {
    return resourceValue > value
  }
  if (operator === 'less_than') {
    return resourceValue < value
  }
  if (operator === 'between') {
    return resourceValue > value && resourceValue < toValue
  }
  if (operator === 'equal') {
    return value === resourceValue
  }
  if (operator === 'equal_case_insensitive') {
    return (value || '').toLowerCase() === (resourceValue || '').toLowerCase()
  }
  if (operator === 'not_equal') {
    return value !== resourceValue
  }
  if (operator === 'not_equal_case_insensitive') {
    return (value || '').toLowerCase() !== (resourceValue || '').toLowerCase()
  }
  if (operator === 'start_with') {
    return (resourceValue || '')
      .toLowerCase()
      .startsWith((value || '').toLowerCase())
  }
  if (operator === 'end_with') {
    return (resourceValue || '')
      .toLowerCase()
      .endsWith((value || '').toLowerCase())
  }
  if (operator === 'contains') {
    return (resourceValue || '').indexOf(value) >= 0
  }
  if (operator === 'all_members_exist') {
    if ((resourceValue || []).length) {
      const matchList = []
      value.forEach((v) => {
        if (resourceValue.indexOf(v) >= 0) {
          matchList.push(v)
        }
      })
      if (matchList.length === value.length) {
        return true
      }
      return false
      // return (resourceValue || []).every((i) => value.indexOf(i) >= 0)
    }
    return false
  }
  if (operator === 'any_member_or_all_members_exist') {
    return (
      Difference(resourceValue || [], value).length <
      (resourceValue || []).length
    )
  }
  if (operator === 'is_blank') {
    return !resourceValue
  }
  if (operator === 'is_not_blank') {
    return resourceValue
  }
  if (operator === 'is_null') {
    return !resourceValue || !(resourceValue || []).filter((e) => e).length
  }
  if (operator === 'is_not_null') {
    return resourceValue && !!(resourceValue || []).filter((e) => e).length
  }
  if (operator === 'is_empty') {
    return !(resourceValue || []).filter((e) => e).length
  }
  if (operator === 'is_not_empty') {
    return !!(resourceValue || []).filter((e) => e).length
  }
  if (operator === 'before') {
    const currentTimestamp = Moment().valueOf()
    const calculatedValue = currentTimestamp - value
    if (resourceValue) {
      return resourceValue < calculatedValue
    }
    return false
  }
  if (operator === 'after') {
    const currentTimestamp = Moment().valueOf()
    const calculatedValue = currentTimestamp + value
    if (resourceValue) {
      return resourceValue > calculatedValue
    }
    return false
  }
  if (operator === 'exactly_next') {
    const valueMomentStartOfDay = Moment().startOf('day').valueOf() + value
    const valueMomentEndOfDay = Moment().endOf('day').valueOf() + value
    if (resourceValue) {
      return (
        resourceValue >= valueMomentStartOfDay &&
        resourceValue <= valueMomentEndOfDay
      )
    }
    return false
  }
  if (operator === 'exactly_last') {
    const valueMomentStartOfDay = Moment().startOf('day').valueOf() - value
    const valueMomentEndOfDay = Moment().endOf('day').valueOf() - value
    if (resourceValue) {
      return (
        resourceValue >= valueMomentStartOfDay &&
        resourceValue <= valueMomentEndOfDay
      )
    }
    return false
  }
  if (operator === 'within_next') {
    const currentTimestamp = Moment().valueOf()
    const calculatedValue = currentTimestamp + value
    if (resourceValue) {
      return resourceValue <= calculatedValue
    }
    return false
  }
  if (operator === 'within_last') {
    const currentTimestamp = Moment().valueOf()
    const calculatedValue = currentTimestamp - value
    if (resourceValue) {
      return resourceValue >= calculatedValue
    }
    return false
  }
  if (operator === 'between_next') {
    const currentTimestamp = Moment().valueOf()
    const v = currentTimestamp + value
    const toV = currentTimestamp + toValue
    return resourceValue > v && resourceValue < toV
  }
  if (operator === 'between_last') {
    const currentTimestamp = Moment().valueOf()
    const v = currentTimestamp - value
    const toV = currentTimestamp - toValue
    return resourceValue < v && resourceValue > toV
  }
  if (operator === 'same_as') {
    const v = /^\d+$/.test(value)
      ? resource[value] /* for custom field */
      : ReferencedSystemFieldValue(
          value,
          resource,
          requesterDetails
        ) /* for system field */
    return v === resourceValue
  }
  if (operator === 'not_same_as') {
    const v = /^\d+$/.test(value)
      ? resource[value] /* for custom field */
      : ReferencedSystemFieldValue(
          value,
          resource,
          requesterDetails
        ) /* for system field */
    return v !== resourceValue
  }
  return false
  // throw new Error(`${operator} Operator not found`)
}

// check group is applicable
export function executeGroup(group, resource, requesterDetails) {
  const operandResultList = []
  group.operands.forEach((operand) => {
    operandResultList.push(executeOperand(operand, resource, requesterDetails))
  })
  return getFinalResult(operandResultList, group.operator)
}

// check qualification is application
export function executeQualification(
  qualification,
  resource,
  requesterDetails
) {
  let qualified = true
  if (!qualification) {
    return qualified
  }
  const groups = qualification.groups || []
  const operators = qualification.operators || []
  if (!groups.length) {
    return qualified
  }
  const groupResultList = []
  groups.forEach((group) => {
    groupResultList.push(executeGroup(group, resource, requesterDetails))
  })
  return getFinalResult(groupResultList, operators[0])
}

export const systemFieldMap = {
  locationId: 'location',
  subject: 'subject',
  impactId: 'impact',
  tags: 'tags',
  ccEmailSet: 'ccemail',
  categoryId: 'category',
  groupId: 'technician_group',
  statusId: 'status',
  departmentId: 'department',
  urgencyId: 'urgency',
  priorityId: 'priority',
  description: 'description',
  technicianId: 'technician',
  vendorId: 'vendor',
}

export const clearValueMapForSystemField = {
  locationId: 0,
  vendorId: 0,
  tags: [],
  ccEmailSet: [],
  categoryId: 0,
  groupId: 0,
  departmentId: 0,
  technicianId: 0,
}

export function clearValueForField(fieldKey, field) {
  if (/^\d+$/.test(fieldKey)) {
    return clearValueForCustomField(field.inputType)
  }
  if (fieldKey in clearValueMapForSystemField) {
    return clearValueMapForSystemField[fieldKey]
  }
  return null
}

export function fieldInputTypeConverter(key) {
  if (/^\d+$/.test(key)) {
    return key
  }
  return systemFieldMap[key]
}

export function performActions(
  moduleName,
  rules,
  formFields,
  previousRulesState,
  updatedResource
) {
  let currentRulesState = { ...previousRulesState }
  rules.forEach((rule) => {
    const result = performAction(
      moduleName,
      rule,
      formFields,
      currentRulesState,
      updatedResource
    )
    currentRulesState = { ...currentRulesState, ...result }
  })
  return currentRulesState
}

// prepair action for qualified qualifications
export function performAction(
  moduleName,
  rule,
  formFields,
  previousRulesState,
  updatedResource
) {
  const fieldRulesState = CloneDeep({ ...previousRulesState })
  if (rule.isQualified) {
    Object.keys(Invert(formRulesTabMap(moduleName))).forEach((tab) => {
      rule.actions.forEach((action) => {
        const attributeAction =
          actionOptions()
            .filter((o) => o.type === 'attribute')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          action.value.length &&
          action.value.indexOf(tab) >= 0
        if (attributeAction) {
          if (action.action === 'disable') {
            fieldRulesState[tab] = {
              ...fieldRulesState[tab],
              disable: true,
              isDirty: true,
            }
          }
        }
      })
    })

    formFields.forEach((f, fieldIndex) => {
      const fieldKey = f.isSystemField ? f.paramName : f.id
      rule.actions.forEach((action) => {
        const attributeAction =
          actionOptions()
            .filter((o) => o.type === 'attribute')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          action.value.length &&
          (action.value.indexOf(f.paramName) >= 0 ||
            action.value.indexOf(`${f.id}`) >= 0)
        const valueAction =
          actionOptions()
            .filter((o) => o.type === 'value')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          (action.fieldId === f.paramName || action.fieldId === `${f.id}`)
        const optionAttributeAction =
          actionOptions()
            .filter((o) => o.type === 'option')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          (action.fieldId === f.paramName || action.fieldId === `${f.id}`)
        const filterDataAction =
          actionOptions()
            .filter((o) => o.type === 'filter_data')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          (action.fieldId === f.paramName || action.fieldId === `${f.id}`)
        if (attributeAction) {
          if (action.action === 'hide') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              hide: true,
              isDirty: true,
            }
          }
          if (action.action === 'show') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              hide: false,
              isDirty: true,
            }
          }
          if (action.action === 'disable') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              disable: true,
              isDirty: true,
            }
          }
          if (action.action === 'enable') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              disable: false,
              isDirty: true,
              // override hide rule
              hide: false,
            }
          }
          if (action.action === 'mandate') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              mandatory: true,
              isDirty: true,
            }
          }
          if (action.action === 'non_mendate') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              mandatory: false,
              isDirty: true,
            }
          }
        }
        if (valueAction) {
          const isDateField =
            f.type === 'datetime' ||
            ['start_date', 'end_date', 'start_time', 'end_time'].indexOf(
              f.inputType
            ) >= 0
          fieldRulesState[fieldKey] = {
            ...fieldRulesState[fieldKey],
            ...(action.action === 'set_value'
              ? {
                  setValue:
                    isDateField &&
                    action.fieldValue === Constants.CURRENT_DATE_VALUE
                      ? Moment().valueOf()
                      : action.fieldValue,
                }
              : {}),
            ...(action.action === 'clear_value'
              ? {
                  clearValue: clearValueForField(fieldKey, f),
                }
              : {}),
            isDirty: true,
          }
        }
        if (optionAttributeAction) {
          fieldRulesState[fieldKey] = {
            ...fieldRulesState[fieldKey],
            ...(action.action === 'show_options'
              ? {
                  visibleOptionsKeys: action.fieldValue,
                  // clearValue: clearValueForField(fieldKey, f),
                }
              : {}),
            ...(action.action === 'hide_options'
              ? {
                  hiddenOptionsKeys: action.fieldValue,
                  // clearValue: clearValueForField(fieldKey, f),
                }
              : {}),
            isDirty: true,
          }
        }
        if (filterDataAction) {
          fieldRulesState[fieldKey] = {
            ...fieldRulesState[fieldKey],
            filterData: {
              key: action.fieldValue.userField || undefined,
              value:
                updatedResource[action.fieldValue.requestField] || undefined,
            },
            a: action.fieldValue,
            isDirty: true,
          }
        }
        if (action.action === 'run_custom_script' && fieldIndex === 0) {
          if (
            (action.customScript || {}).content &&
            (fieldRulesState.executableCustomScripts || []).indexOf(
              (action.customScript || {}).content
            ) === -1
          ) {
            fieldRulesState.executableCustomScripts = [
              ...(fieldRulesState.executableCustomScripts || []),
              (action.customScript || {}).content,
            ]
          }
        }
      })
    })
  } else if (rule.reversibleAction) {
    // this is revese rule
    Object.keys(Invert(formRulesTabMap(moduleName))).forEach((tab) => {
      rule.actions.forEach((action) => {
        const attributeAction =
          actionOptions()
            .filter((o) => o.type === 'attribute')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          action.value.length &&
          action.value.indexOf(tab) >= 0
        if (attributeAction) {
          if (action.action === 'disable') {
            fieldRulesState[tab] = {
              ...fieldRulesState[tab],
              disable: false,
              isDirty: true,
            }
          }
        }
      })
    })
    formFields.forEach((f, fieldIndex) => {
      const fieldKey = f.isSystemField ? f.paramName : f.id
      rule.actions.forEach((action) => {
        const attributeAction =
          actionOptions()
            .filter((o) => o.type === 'attribute')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          action.value.length &&
          (action.value.indexOf(f.paramName) >= 0 ||
            action.value.indexOf(`${f.id}`) >= 0)
        const optionAttributeAction =
          actionOptions()
            .filter((o) => o.type === 'option')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          (action.fieldId === f.paramName || action.fieldId === `${f.id}`)
        const filterDataAction =
          actionOptions()
            .filter((o) => o.type === 'filter_data')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          (action.fieldId === f.paramName || action.fieldId === `${f.id}`)
        if (attributeAction) {
          if (action.action === 'hide') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              hide: false,
              isDirty: true,
            }
          }
          if (action.action === 'show') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              hide: true,
              isDirty: true,
            }
          }
          if (action.action === 'disable') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              disable: false,
              isDirty: true,
            }
          }
          if (action.action === 'enable') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              disable: true,
              isDirty: true,
            }
          }
          if (action.action === 'mandate') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              mandatory: false,
              isDirty: true,
            }
          }
          if (action.action === 'non_mendate') {
            fieldRulesState[fieldKey] = {
              ...fieldRulesState[fieldKey],
              mandatory: true,
              isDirty: true,
            }
          }
        }
        if (optionAttributeAction) {
          fieldRulesState[fieldKey] = {
            ...fieldRulesState[fieldKey],
            ...(action.action === 'show_options'
              ? {
                  visibleOptionsKeys: [],
                }
              : {}),
            ...(action.action === 'hide_options'
              ? {
                  hiddenOptionsKeys: [],
                }
              : {}),
            isDirty: true,
          }
        }
        if (filterDataAction) {
          delete fieldRulesState[fieldKey].filterData
        }
        if (action.action === 'run_custom_script' && fieldIndex === 0) {
          fieldRulesState.executableCustomScripts = (
            fieldRulesState.executableCustomScripts || []
          ).filter((s) => s !== (action.customScript || {}).content)
        }
      })
    })
  } else {
    formFields.forEach((f, fieldIndex) => {
      const fieldKey = f.isSystemField ? f.paramName : f.id
      rule.actions.forEach((action) => {
        const filterDataAction =
          actionOptions()
            .filter((o) => o.type === 'filter_data')
            .map((i) => i.key)
            .indexOf(action.action) >= 0 &&
          (action.fieldId === f.paramName || action.fieldId === `${f.id}`)
        if (filterDataAction) {
          delete fieldRulesState[fieldKey].filterData
        }
        if (action.action === 'run_custom_script' && fieldIndex === 0) {
          fieldRulesState.executableCustomScripts = (
            fieldRulesState.executableCustomScripts || []
          ).filter((s) => s !== (action.customScript || {}).content)
        }
      })
    })
  }
  return fieldRulesState
}
