export function transformManufacturerCatalog(manufacturercatalog) {
  return {
    id: manufacturercatalog.id,
    name: manufacturercatalog.name,
    //  text: `${manufacturercatalog.name} ${manufacturercatalog.displayName}`,
    text: manufacturercatalog.displayName,
    // vendorSysOidSet: manufacturercatalog.vendorSysOidSet,
    displayName: manufacturercatalog.displayName,
    description: manufacturercatalog.description,
    canDelete: manufacturercatalog.deleteableOob,
    canEdit: manufacturercatalog.updatebleOob,
  }
}

export function transformManufacturerCatalogForServer(manufacturercatalog) {
  return {
    id: manufacturercatalog.id,
    name: manufacturercatalog.name,
    // vendorSysOidSet: manufacturercatalog.vendorSysOidSet,
    displayName: manufacturercatalog.displayName,
    description: manufacturercatalog.description,
    canDelete: manufacturercatalog.deleteableOob,
    canEdit: manufacturercatalog.updatebleOob,
  }
}
