export function transformAnnouncementForReadOnly(a) {
  return {
    id: a.id,
    name: a.name,
    announcementDescription: a.detail,
    createdBy: a.createdById,
    updatedAt: a.startTime,
  }
}

export function transformAnnouncement(a) {
  return {
    id: a.id,
    name: a.name,
    announcementDescription: a.detail,
    startTime: a.startTime,
    endTime: a.endTime,
    duration: a.duration,
    durationUnit: a.durationUnit,
    sPortalCompanyIds: a.sPortalCompanyIds,
    status: a.status,
    // sendAsEmail: a.sendAsEmailAlso,
    showOnSupportPortal: a.enableCportal,
    canDelete: a.deleteableOob,
    enabled: !a.disabled,
    canEdit: a.updatebleOob,
    ...(a.enableCportal
      ? {
          supportPortalPermission: a.cportalPermission,
          requesterGroups: a.requesterGroupIds || [],
        }
      : {}),
    showOnTechnicianPortal: a.enableTechnicianPortal,
    ...(a.enableTechnicianPortal
      ? {
          technicianPortalPermission: a.technicianPortalPermission,
          technicianGroups: a.technicianGroupIds || [],
        }
      : {}),
  }
}

export function transformAnnouncementForServer(a) {
  return {
    name: a.name,
    detail: a.announcementDescription,
    startTime: a.startTime,
    endTime: a.endTime,
    duration: a.duration,
    durationUnit: a.durationUnit,
    sPortalCompanyIds: a.sPortalCompanyIds,
    // sendAsEmailAlso: a.sendAsEmail,
    enableCportal: a.showOnSupportPortal,
    disabled: a.disabled || false,
    ...(a.showOnSupportPortal
      ? {
          cportalPermission: a.supportPortalPermission,
          requesterGroupIds: a.requesterGroups || [],
        }
      : {
          cportalPermission: null,
          requesterGroupIds: null,
        }),
    enableTechnicianPortal: a.showOnTechnicianPortal,
    ...(a.showOnTechnicianPortal
      ? {
          technicianPortalPermission: a.technicianPortalPermission,
          technicianGroupIds: a.technicianGroups || [],
        }
      : {
          technicianPortalPermission: null,
          technicianGroupIds: null,
        }),
  }
}
