import {
  transformValueType,
  transformValueTypeForServer,
} from '@data/value-type'
import { getEncryptedPassword, getDecryptedPassword } from '@utils/password'
import {
  transformAttachment,
  transformAttachmentForServer,
} from '@data/attachment'
import {
  transformAvailableQualification,
  transformQualificationForServer,
  transformQualification,
} from './qualification'
import { generateId } from '../utils/id'
import SortBy from 'lodash/sortBy'
import Constants from '@constants'

export function transformQuestions(data) {
  return {
    id: data.id,
    key: data.question,
    text: data.question,
    name: data.question,
    canEdit: data.updatebleOob,
    canDelete: data.deleteableOob,
    disabled: false,
  }
}

export function transformQuestionsForServer(data) {
  return {
    question: data.name,
  }
}

export function transformSupportData(data) {
  return {
    qualifications: (data.qualifications || []).map(
      transformAvailableQualification
    ),
  }
}

export function transformIncomingEmailConfigForServer(config) {
  return {
    name: config.name,
    server: config.server,
    protocol: config.protocol,
    password: config.password,
    replyToEmail: config.replyToEmail,
    senderName: config.senderName,
    disabled: !config.enabled,
    asssignGroupId: config.technicianGroups,
    isPrimary: config.primary,
    email: config.email,
    idle: config.idle,
    enableOutgoingMail: config.enableOutgoingMail,
    companyId: config.companyId,
    categoryId: config.categoryId,
    emailAuthType: config.emailAuthType,
    emailProvider: config.emailProvider,
    proxyConfigId: config.proxyConfigId,
    ...(config.emailProvider !== 'other' ? { code: config.code } : {}),
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getEncryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getEncryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
          code: config.code,
        }
      : {}),
    ...(config.enableOutgoingMail
      ? {
          outMailConfigID: config.outMailConfigID,
        }
      : {}),
    ...(config.protocol !== 'mapi'
      ? {
          port: config.port,
          securityType: config.securityType,
        }
      : {}),
    ...(config.spamFilter
      ? {
          spamFilter: {
            spamKeyword: config.spamFilter.spamKeyword,
            filterType: config.spamFilter.filterType,
            emails: config.spamFilter.emails,
            domains: config.spamFilter.domains,
          },
        }
      : {}),
  }
}

export function transformIncomingEmailConfig(config) {
  return {
    id: config.id,
    name: config.name,
    server: config.server,
    port: config.port,
    protocol: config.protocol,
    securityType: config.securityType,
    email: config.email,
    password: config.password,
    replyToEmail: config.replyToEmail,
    senderName: config.senderName,
    enabled: !config.disabled,
    technicianGroups: config.asssignGroupId,
    primary: config.isPrimary,
    enableOutgoingMail: config.enableOutgoingMail,
    idle: config.idle,
    outMailConfigID: config.outMailConfigID,
    companyId: config.companyId,
    categoryId: config.categoryId,
    emailAuthType: config.emailAuthType,
    emailProvider: config.emailProvider,
    proxyConfigId: config.proxyConfigId,
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getDecryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getDecryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
        }
      : {}),
    ...(config.spamFilter
      ? {
          spamFilter: {
            spamKeyword: config.spamFilter.spamKeyword,
            filterType: config.spamFilter.filterType,
            emails: config.spamFilter.emails,
            domains: config.spamFilter.domains,
          },
        }
      : {}),
  }
}

export const defaultIncomingEmailConfig = {
  enabled: true,
  emailAuthType: 'basic_auth',
  spamFilter: {
    spamKeyword: undefined,
    filterType: 'ignore',
    emails: undefined,
    domains: undefined,
  },
}

export const defaultOutgoingEmailConfig = {
  enabled: true,
  emailAuthType: 'basic_auth',
  spamFilter: {
    filterType: 'ignore',
    emails: undefined,
    domains: undefined,
  },
  // emailProvider: 'other',
}

export function transformOutgoingEmailConfig(config) {
  return {
    id: config.id,
    name: config.name,
    server: config.server,
    protocol: config.protocol,
    port: config.port,
    securityType: config.securityType,
    email: config.email,
    userName: config.userName,
    password: config.password,
    replyToEmail: config.replyToEmail,
    senderName: config.senderName,
    enabled: !config.disabled,
    primary: config.isDefault,
    authenticationNeeded: config.authenticationNeeded,
    emailAuthType: config.emailAuthType,
    emailProvider: config.emailProvider,
    proxyConfigId: config.proxyConfigId,
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getDecryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getDecryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
        }
      : {}),
    ...(config.spamFilter
      ? {
          spamFilter: {
            filterType: config.spamFilter.filterType,
            emails: config.spamFilter.emails,
            domains: config.spamFilter.domains,
          },
        }
      : {}),
  }
}

export function transformEmailConfigForServer(config) {
  return {
    server: config.server,
    protocol: config.protocol,
    password: config.password || '',
    replyToEmail: config.replyToEmail,
    disabled: !config.enabled,
    authenticationNeeded: config.authenticationNeeded,
    email: config.email,
    userName: config.userName,
    emailAuthType: config.emailAuthType,
    proxyConfigId: config.proxyConfigId,
    ...(config.emailAuthType === 'oauth'
      ? {
          ...(config.protocol !== 'imap'
            ? {
                clientSecret: config.clientSecret
                  ? getEncryptedPassword(config.clientSecret)
                  : undefined,
              }
            : {}),
          clientId: config.clientId
            ? getEncryptedPassword(config.clientId)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
        }
      : {}),
    ...(config.protocol !== 'mapi'
      ? {
          port: config.port,
          securityType: config.securityType,
          senderName: config.senderName,
        }
      : {}),
  }
}

export function transformOutgoingEmailConfigForServer(config) {
  return {
    name: config.name,
    server: config.server,
    protocol: config.protocol,
    disabled: !config.enabled,
    email: config.email,
    password: config.password || '',
    replyToEmail: config.replyToEmail,
    authenticationNeeded: config.authenticationNeeded,
    isDefault: config.primary,
    userName: config.userName,
    emailAuthType: config.emailAuthType,
    emailProvider: config.emailProvider,
    proxyConfigId: config.proxyConfigId,
    ...(config.emailProvider !== 'other' ? { code: config.code } : {}),
    ...(config.emailAuthType === 'oauth'
      ? {
          clientId: config.clientId
            ? getEncryptedPassword(config.clientId)
            : undefined,
          clientSecret: config.clientSecret
            ? getEncryptedPassword(config.clientSecret)
            : undefined,
          authUrl: config.authUrl,
          tokenUrl: config.tokenUrl,
          scope: config.scope,
          code: config.code,
        }
      : {}),
    ...(config.protocol !== 'mapi'
      ? {
          port: config.port,
          securityType: config.securityType,
          senderName: config.senderName,
        }
      : {}),
    ...(config.spamFilter
      ? {
          spamFilter: {
            filterType: config.spamFilter.filterType,
            emails: config.spamFilter.emails,
            domains: config.spamFilter.domains,
          },
        }
      : {}),
  }
}

// support portal configuration transform
export function transformSupportPortalConfig(config) {
  return {
    allowGuestToReportRequest: config.allowGuestToReportRequest,
    allowGuestRequestersToRequestForService:
      config.allowGuestRequestersToRequestForService,
    allowSelfRegistration: config.allowSelfRegistration,
    selfRegistrationType: config.selfRegistrationType,
    selfServiceSetting: config.selfServiceSetting,
    domains: config.domainNames,
    showRelatedKnowledge: config.showRelatedKnowledge,
    allowToLinkAsset: config.allowToLinkAsset,
    allowToLinkCi: config.allowToLinkCi,
    allowToReopenClosedReq: config.allowToReopenClosedReq,
    allowToCloseReq: config.allowToCloseReq,
    closedGraceTimeType: config.closedGraceTimeType,
    closedGraceTime: config.closedGraceTime,
    allowToReopenResolvedReq: config.allowToReopenResolvedReq,
    resolvedGraceTimeType: config.resolvedGraceTimeType,
    resolvedGraceTime: config.resolvedGraceTime,
    allowReqToAccessMyAsset: config.allowReqToAccessMyAsset,
    allowReqToAccessMyCi: config.allowReqToAccessMyCi,
    allowRequesterToViewBarcodeAndQRCode:
      config.allowRequesterToViewBarcodeAndQRCode,
    allowRequesterToAccessKB: config.allowRequesterToAccessKB,
    allowRequesterToAccessServiceRequest:
      config.allowRequesterToAccessServiceRequest,
    allowRequesterToAccessMyApprovals: config.allowRequesterToAccessMyApprovals,
    allowRequesterToSubmitFeedback: config.allowRequesterToSubmitFeedback,
    allowRequesterToAccestTicketApprovalTab: config.showApprovalTab,
    allowReqToAccessMyChanges: config.allowReqToAccessMyChanges,
    allowRequesterToCreateRequestBehalfOfOtherRequester:
      config.allowRequesterToCreateRequestBehalfOfOtherRequester,
    allowRequesterToViewAuditTrail: config.allowRequesterToViewAuditTrail,
    allowRequesterToCreateIncidentBehalfOfOtherRequester:
      config.allowRequesterToCreateIncidentBehalfOfOtherRequester,
    allowRequesterToCreateIncidents: config.allowRequesterToCreateIncidents,
    mandateCommentToReopenRequest: config.mandateCommentToReopenRequest,
  }
}

export function transformSupportPortalConfigForServer(config) {
  return {
    allowGuestToReportRequest: config.allowGuestToReportRequest,
    allowGuestRequestersToRequestForService:
      config.allowGuestRequestersToRequestForService,
    allowSelfRegistration: config.allowSelfRegistration,
    selfRegistrationType: config.selfRegistrationType,
    domainNames: config.domains,
    showRelatedKnowledge: config.showRelatedKnowledge,
    allowToLinkAsset: config.allowToLinkAsset,
    allowToLinkCi: config.allowToLinkCi,
    allowToReopenClosedReq: config.allowToReopenClosedReq,
    allowToCloseReq: config.allowToCloseReq,
    closedGraceTimeType: config.closedGraceTimeType,
    closedGraceTime: config.closedGraceTime,
    allowToReopenResolvedReq: config.allowToReopenResolvedReq,
    resolvedGraceTimeType: config.resolvedGraceTimeType,
    resolvedGraceTime: config.resolvedGraceTime,
    allowReqToAccessMyAsset: config.allowReqToAccessMyAsset,
    allowReqToAccessMyCi: config.allowReqToAccessMyCi,
    allowRequesterToViewBarcodeAndQRCode:
      config.allowRequesterToViewBarcodeAndQRCode,
    allowRequesterToAccessKB: config.allowRequesterToAccessKB,
    allowRequesterToAccessServiceRequest:
      config.allowRequesterToAccessServiceRequest,
    allowRequesterToAccessMyApprovals: config.allowRequesterToAccessMyApprovals,
    allowRequesterToSubmitFeedback: config.allowRequesterToSubmitFeedback,
    showApprovalTab: config.allowRequesterToAccestTicketApprovalTab,
    allowReqToAccessMyChanges: config.allowReqToAccessMyChanges,
    allowRequesterToCreateRequestBehalfOfOtherRequester:
      config.allowRequesterToCreateRequestBehalfOfOtherRequester,
    allowRequesterToViewAuditTrail: config.allowRequesterToViewAuditTrail,
    allowRequesterToCreateIncidentBehalfOfOtherRequester:
      config.allowRequesterToCreateIncidentBehalfOfOtherRequester,
    allowRequesterToCreateIncidents: config.allowRequesterToCreateIncidents,
    mandateCommentToReopenRequest: config.mandateCommentToReopenRequest,
  }
}

// chat configuration

export function transformChatConfig(config) {
  return {
    id: config.id,
    enabled: !config.disabled,
    userIds: config.userIds,
    groupIds: config.groupIds,
    welcomeMessage: config.welcomeMessage,
    missedMessage: config.missedMessage,
    fontFamily: config.fontFamily || '',
    timeout: config.timeout,
    collaborationSupport: config.collaborationSupport,
    userFirstNameOnly: config.displayFirstName,
    botSupport: config.botSupport,
    greetingStoryId: config.greetingStoryId,
    fallbackStoryId: config.fallbackStoryId,
    viberFallbackStoryId: config.viberFallbackStoryId,
    telegramFallbackStoryId: config.telegramFallbackStoryId,
    whatsAppFallbackStoryId: config.whatsAppFallbackStoryId,
    messengerFallbackStoryId: config.messengerFallbackStoryId,
    teamsFallbackStoryId: config.teamsFallbackStoryId,
    slackFallbackStoryId: config.slackFallbackStoryId,
    chatLogo: config.chatLogo
      ? (config.chatLogo || []).map(transformAttachment)
      : undefined,
    ...((config.chatLogo || []).length
      ? {
          chatLogoSrc: `/api/public/download/chat/logo`,
        }
      : {}),
    chatFavicon: config.chatFavicon
      ? (config.chatFavicon || []).map(transformAttachment)
      : undefined,
    ...((config.chatFavicon || []).length
      ? {
          chatFaviconSrc: `/api/public/download/chat/favicon`,
        }
      : {}),
    viewUserChatHistory: config.viewUserChatHistory,
  }
}

export function transformChatConfigForServer(config) {
  return {
    id: config.id,
    disabled: !config.enabled,
    userIds: config.userIds,
    groupIds: config.groupIds,
    welcomeMessage: config.welcomeMessage,
    missedMessage: config.missedMessage,
    fontFamily: config.fontFamily || '',
    timeout: config.timeout,
    collaborationSupport: config.collaborationSupport,
    displayFirstName: config.userFirstNameOnly,
    botSupport: config.botSupport,
    greetingStoryId: config.greetingStoryId || 0,
    fallbackStoryId: config.fallbackStoryId || 0,
    viberFallbackStoryId: config.viberFallbackStoryId,
    telegramFallbackStoryId: config.telegramFallbackStoryId,
    whatsAppFallbackStoryId: config.whatsAppFallbackStoryId,
    messengerFallbackStoryId: config.messengerFallbackStoryId,
    teamsFallbackStoryId: config.teamsFallbackStoryId,
    slackFallbackStoryId: config.slackFallbackStoryId,
    chatLogo:
      config.chatLogo && config.chatLogo.length
        ? (config.chatLogo || []).map(transformAttachmentForServer)
        : [],
    chatFavicon:
      config.chatFavicon && config.chatFavicon.length
        ? (config.chatFavicon || []).map(transformAttachmentForServer)
        : [],
    viewUserChatHistory: config.viewUserChatHistory,
  }
}

// prefrences transform

export const transformPreferences = (preferences, allowedKeys) => {
  const filteredPreference = (preferences.preferences || []).filter(
    (preference) => {
      return allowedKeys.indexOf(preference.propertyKey) >= 0
    }
  )
  const map = {}
  filteredPreference.forEach((preference) => {
    map[preference.propertyKey] = {
      propertyKey: preference.propertyKey,
      ...transformValueType(preference.valueRest),
    }
  })
  return map
}
export const transformPreferencesForServer = (preference) => ({
  preferences: [
    {
      propertyKey: preference.propertyKey,
      valueRest: transformValueTypeForServer(
        preference.valueType,
        preference.value
      ).value,
    },
  ],
})

export function transformOutfoingEmailRuleConfig(data) {
  return {
    outgoingMailRules: SortBy(
      (data.outgoingMailRules || []).map(transformEmailRules) || [],
      'ruleOrder'
    ),
  }
}

export function transformEmailRules(data) {
  return {
    guid: generateId(),
    enabled: !data.disabled,
    ruleOrder: data.ruleOrder,
    targetMailSendConfigId: data.targetMailSendConfigId,
    targetModel: data.targetModel || Constants.REQUEST,
    qualifications: transformQualification(
      data.qualification || { groups: [], operators: [] }
    ),
  }
}

export function transformOutfoingEmailRuleConfigForServer(data) {
  return {
    outgoingMailRules:
      (data.outgoingMailRules || []).map(transformEmailRulesForServer) || [],
  }
}

export function transformEmailRulesForServer(data) {
  return {
    disabled: !data.enabled,
    ruleOrder: data.ruleOrder,
    targetMailSendConfigId: data.targetMailSendConfigId,
    targetModel: data.targetModel || Constants.REQUEST,
    qualification: transformQualificationForServer(data.qualifications),
  }
}
