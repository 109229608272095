export function transformVendorCatalog(vendorcatalog) {
  return {
    id: vendorcatalog.id,
    name: vendorcatalog.name,
    // text: `${vendorcatalog.name} ${vendorcatalog.displayName}`,
    text: vendorcatalog.displayName,
    displayName: vendorcatalog.displayName,
    contactPerson: vendorcatalog.contactPersonName,
    contactNo: vendorcatalog.phone,
    email: vendorcatalog.email,
    description: vendorcatalog.description,
    address: {
      street: vendorcatalog.streetLine1,
      landmark: vendorcatalog.streetLine2,
      city: vendorcatalog.city,
      pinCode: vendorcatalog.zipCode,
      state: vendorcatalog.state,
      country: vendorcatalog.country,
    },
    canDelete: vendorcatalog.deleteableOob,
    canEdit: vendorcatalog.updatebleOob,
    fieldValueDetails: vendorcatalog.fieldValueDetails,
  }
}

export function transformVendorCatalogForServer(vendorcatalog) {
  return {
    id: vendorcatalog.id,
    name: vendorcatalog.name,
    displayName: vendorcatalog.displayName,
    contactPersonName: vendorcatalog.contactPerson,
    phone: vendorcatalog.contactNo,
    email: vendorcatalog.email,
    description: vendorcatalog.description,
    streetLine1: vendorcatalog.address.street,
    streetLine2: vendorcatalog.address.landmark,
    city: vendorcatalog.address.city,
    zipCode: vendorcatalog.address.pinCode,
    state: vendorcatalog.address.state,
    country: vendorcatalog.address.country,
    fieldValueDetails: vendorcatalog.fieldValueDetails,
  }
}
