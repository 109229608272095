export function transformPriority(priority) {
  return {
    id: priority.id,
    name: priority.name,
    text: priority.name,
    key: priority.id,
    color: priority.colourCode,
    archived: priority.archived,
    createdById: priority.createdById,
    disabled: priority.inActive,
    oob: priority.oob,
    default: priority.isDefault,
    canDelete: priority.deleteableOob,
    canEdit: priority.updatebleOob,
  }
}

export function transformPriorityForServer(priority) {
  return {
    name: priority.name,
    colourCode: priority.color,
    isDefault: priority.default ? priority.default : undefined,
  }
}

export function transformPriorityMatrix(priorityMatrix) {
  return {
    id: priorityMatrix.id,
    impactId: priorityMatrix.impactId,
    priorityId: priorityMatrix.priorityId,
    urgencyId: priorityMatrix.urgencyId,
    createdById: priorityMatrix.createdById,
    createdTime: priorityMatrix.createdTime,
    canDelete: priorityMatrix.deleteableOob,
    canEdit: priorityMatrix.updatebleOob,
  }
}

export function transformPriorityMatrixForServer(priorityMatrix) {
  return {
    impactId: priorityMatrix.impactId,
    priorityId: priorityMatrix.priorityId,
    urgencyId: priorityMatrix.urgencyId,
  }
}
