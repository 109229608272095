import Moment from 'moment'
import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __tc = getRootPluaralTranslator()

export default function daytime(value) {
  const m = Moment.duration(value, 'milliseconds')
  if (m.asMinutes() < 60) {
    return `${m.asMinutes()} ${__tc('minutes', m.asMinutes())}`
  }
  if (m.asDays() >= 1) {
    if (m.asHours() % 24 <= 0) {
      return `${m.asDays()} ${__tc('days', m.asDays())}`
    }
    return `${m.asHours()} ${__tc('hours', m.asHours())}`
  }

  return `${m.asHours()} ${__tc('hours', m.asHours())}`
}
