export function transformReasonType(reasonType) {
  return {
    id: reasonType.id,
    name: reasonType.name,
    text: reasonType.name,
    key: reasonType.id,
    color: reasonType.colourCode,
    disabled: reasonType.inActive,
    oob: reasonType.oob,
    archived: reasonType.removed,
    canDelete: reasonType.deleteableOob,
    canEdit: reasonType.updatebleOob,
    default: reasonType.isDefault,
  }
}

export function transformReasonTypeForServer(reasonType) {
  return {
    name: reasonType.name,
    colourCode: reasonType.color,
  }
}
