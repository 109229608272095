export function transformWatcher(watcher) {
  return {
    technicians: watcher.technicianIds || [],
    technicianGroups: watcher.technicianGroupIds || [],
  }
}

export function transformWatcherForServer(watcher) {
  return {
    technicianIds: watcher.technicians,
    technicianGroupIds: watcher.technicianGroups,
  }
}
