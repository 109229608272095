export function transformRiskType(riskType) {
  return {
    id: riskType.id,
    name: riskType.name,
    text: riskType.name,
    key: riskType.id,
    color: riskType.colourCode,
    disabled: riskType.inActive,
    oob: riskType.oob,
    archived: riskType.removed,
    canDelete: riskType.deleteableOob,
    canEdit: riskType.updatebleOob,
    default: riskType.isDefault,
  }
}

export function transformRiskTypeForServer(riskType) {
  return {
    name: riskType.name,
    colourCode: riskType.color,
  }
}

export function transformChangeType(changeType) {
  return {
    id: changeType.id,
    name: changeType.name,
    text: changeType.name,
    key: changeType.id,
    color: changeType.colourCode,
    disabled: changeType.inActive,
    oob: changeType.oob,
    archived: changeType.removed,
    canDelete: changeType.deleteableOob,
    canEdit: changeType.updatebleOob,
    default: changeType.isDefault,
  }
}

export function transformChangeTypeForServer(changeType) {
  return {
    name: changeType.name,
    colourCode: changeType.color,
  }
}

export function transformReasonType(reasonType) {
  return {
    id: reasonType.id,
    name: reasonType.name,
    text: reasonType.name,
    key: reasonType.id,
    color: reasonType.colourCode,
    disabled: reasonType.inActive,
    oob: reasonType.oob,
    archived: reasonType.removed,
    canDelete: reasonType.deleteableOob,
    canEdit: reasonType.updatebleOob,
    default: reasonType.isDefault,
  }
}

export function transformReasonTypeForServer(reasonType) {
  return {
    name: reasonType.name,
    colourCode: reasonType.color,
  }
}

export function transformTargetEnvironment(targetEnv) {
  return {
    id: targetEnv.id,
    name: targetEnv.name,
    text: targetEnv.name,
    key: targetEnv.id,
    color: targetEnv.colourCode,
    disabled: targetEnv.inActive,
    oob: targetEnv.oob,
    archived: targetEnv.removed,
    canDelete: targetEnv.deleteableOob,
    canEdit: targetEnv.updatebleOob,
    default: targetEnv.isDefault,
  }
}

export function transformTargetEnvironmentForServer(targetEnv) {
  return {
    name: targetEnv.name,
  }
}
