import { generateId } from '@utils/id'
import {
  transformQualificationForServer,
  transformQualification,
} from './qualification'

export function transformComputerScopeRests(computerScopeRests) {
  return (computerScopeRests || []).map((item) => ({
    remoteOfficeId: item.remoteOfficeId,
    inclusionQualifications: transformQualification(
      item.inclusionQual || { groups: [], operators: [] }
    ),
    exlusionQualifications: transformQualification(
      item.exlusionQual || { groups: [], operators: [] }
    ),
  }))
}

export function transformComputerScopeRestsForServer(computerScopeRests) {
  return (computerScopeRests || []).map((item) => ({
    remoteOfficeId: item.remoteOfficeId,
    inclusionQual: item.inclusionQualifications
      ? transformQualificationForServer(item.inclusionQualifications)
      : null,
    exlusionQual: item.exlusionQualifications
      ? transformQualificationForServer(item.exlusionQualifications)
      : null,
  }))
}

export function transformRemoteDeploymentForList(deployment) {
  return {
    guid: generateId(),
    id: deployment.id,
    parentId: deployment.parentId,
    name: deployment.name,
    displayName: deployment.displayName,
    subject: deployment.displayName,
    remoteDeployStage: deployment.remoteDeployStage,
    assetConfiguration: deployment.assetConfiguration,
    platform: deployment.platform,
    deploymentPolicyId: deployment.deploymentPolicyId,
    installAfterTime: deployment.installAfterTime,
    expireTime: deployment.expireTime,
    configType: deployment.configType,
    action: deployment.action,
    ...(deployment.configType === 'patch'
      ? { patchIds: (deployment.configuration || []).map((c) => c.configId) }
      : {}),
    ...(deployment.configType === 'registry'
      ? { registryIds: (deployment.configuration || []).map((c) => c.configId) }
      : {}),
    ...(deployment.configType === 'packages'
      ? { packagesIds: deployment.configuration || [] }
      : {}),
    ...(deployment.configType === 'asset_configuration'
      ? {
          usbConfigItems: (deployment.configuration || []).map((c) => ({
            id: c.configId,
            ...c,
          })),
        }
      : {}),
    agentIds: deployment.agentIds,
    approvalStatus: deployment.approvalStatus,
    origin: deployment.origin,
    testStatus: deployment.testStatus,
    enableRetryForFail: deployment.enableRetryForFail,
    startUpRetryCount: deployment.startUpRetryCount,
    refreshRetryCount: deployment.refreshRetryCount,
    retryCount: deployment.retryCount,
    notifiedTechnicians: deployment.notifiedTechnicians,
    totalInstallations: deployment.totalInstallations,
    // windows specific details
    observationStartTime: deployment.observationStartTime,
    remainingApprovalTime: deployment.remainingApprovalTime,
    // date field
    canDelete: deployment.deleteableOob,
    canEdit: deployment.updatebleOob,
    createdAt: deployment.createdTime,
    archived: deployment.removed,
    updatedAt: deployment.updatedTime,
    computerScopeRests: transformComputerScopeRests(
      deployment.computerScopeRests
    ),
    createdById: deployment.createdById,
  }
}

export function transformRemoteDeploymentForDetail(deployment) {
  const listProps = transformRemoteDeploymentForList(deployment)
  return {
    ...listProps,
  }
}

export function transformRemoteDeploymentForServer(deployment) {
  const transformedProps = ['guid', 'patchIds', 'computerScopeRests']
  const obj = {}
  if ('patchIds' in deployment && deployment.configType === 'patch') {
    obj.configuration = (deployment.patchIds || []).map((id) => ({
      configId: id,
    }))
  }
  if ('registryIds' in deployment && deployment.configType === 'registry') {
    obj.configuration = (deployment.registryIds || []).map((id) => ({
      configId: id,
    }))
  }
  if ('packagesIds' in deployment && deployment.configType === 'packages') {
    obj.configuration = (deployment.packagesIds || []).map((item) => ({
      configId: item.configId,
      userType: item.userType,
    }))
  }
  if (
    'usbConfigItems' in deployment &&
    deployment.configType === 'asset_configuration'
  ) {
    obj.configuration = (deployment.usbConfigItems || []).map((item) => ({
      configId: item.configId,
      userType: item.userType,
    }))
  }
  if ('computerScopeRests' in deployment) {
    obj.computerScopeRests = transformComputerScopeRestsForServer(
      deployment.computerScopeRests
    )
  }
  Object.keys(deployment).forEach((paramName) => {
    if (!transformedProps.includes(paramName)) {
      obj[paramName] = deployment[paramName]
    }
  })
  if ('description' in deployment) {
    obj.description = deployment.description || ''
  }
  return obj
}
