import Moment from 'moment'
export function transformPatchApprovalPolicy(patchApproval) {
  return {
    id: patchApproval.id,
    approvalType: patchApproval.approvalType,
    recurringStartHour: patchApproval.flotoSchedule
      ? patchApproval.flotoSchedule.recurringStartHour
      : 0,
  }
}
export function transformPatchApprovalPolicyForServer(patchApproval) {
  return {
    approvalType: patchApproval.approvalType,
    ...(patchApproval.approvalType === 'few_patches_will_approved_by_test' &&
    'recurringStartHour' in patchApproval
      ? {
          flotoSchedule: {
            type: 'FlotoDailyScheduleRest',
            recurringStartHour: patchApproval.recurringStartHour,
            startAt: Moment().valueOf(),
          },
        }
      : {}),
  }
}
