export function transformProjectForList(project) {
  return {
    id: project.id,
    name: project.name,
    displayName: project.displayName,
    subject: project.displayName,
    projectStatus: project.projectStatus,
    priorityId: project.priorityId,
    technicianId: project.technicianId,
    groupId: project.groupId,
    startDate: project.startDate,
    endDate: project.endDate,
    relationId: project.relationId,
    relationType: project.relationType,
    dueBy: project.dueBy,
    completedTask: project.completedTask,
    completedMilestone: project.completedMilestone,
    completedProject: project.completedProject,
    projectTypeId: project.projectTypeId,
    riskTypeId: project.riskTypeId,
    locationId: project.locationId,
    vendorId: project.vendorId,
    tags: project.tags,
    description: project.description,
    createdTime: project.createdTime,
    planningStartDate: project.planningStartDate,
    planningEndDate: project.planningEndDate,
    implementationStartDate: project.implementationStartDate,
    implementationEndDate: project.implementationEndDate,
    closedTime: project.closeDate,
    updatedTime: project.updatedTime,
    fileAttachments: project.fileAttachments,
    fieldValueDetails: project.fieldValueDetails,
    ...(project.fieldValueDetails || {}),
    members: project.members,
    archived: project.removed,
    canDelete: project.deleteableOob,
    canEdit: project.updatebleOob,
    createdAt: project.createdTime,
    updatedAt: project.updatedTime,
    closedAt: project.closedDate,
    requesterId: project.createdById,
    companyId: project.companyId,
    projectSource: project.projectSource,
  }
}
export function transformProjectForDetail(project) {
  const listProps = transformProjectForList(project)
  return {
    ...listProps,
    fieldValueDetails: project.fieldValueDetails || {},
    urgencyId: project.urgencyId,
    impactId: project.impactId,
    tags: project.tags,
    description: project.description,
    locationId: project.locationId,
    vendorId: project.vendorId,
    startDate: project.startDate,
    endDate: project.endDate,
    ...(project.fieldValueDetails || {}),
  }
}

export function transformProject(project) {
  return {
    ...transformProjectForList(project),
    projectStatus: project.projectStatus,
    members: project.members,
  }
}

export function transformProjectForServer(project) {
  const transformedProps = ['fieldValueDetails']
  const obj = {
    ...(project.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...project.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(project).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = project[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: project[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = project[paramName]
    }
  })
  if ('description' in project) {
    obj.description = project.description || ''
  }
  return obj
}
export function transformMember(member) {
  return {
    id: member.memberId,
    memberName: member.memberName,
    projectRole: member.projectRole,
    completedTask: member.completedTask,
    completedMilestone: member.completedMilestone,
    isOwner: member.isOwner,
  }
}
