import { transformRecursive } from './recursive'
import { buildImageUrlFromRefFileName } from '@data/attachment'

export function transformAssetTypeRecursive(assetTypes) {
  return transformRecursive(assetTypes, transformAssetType)
}

export function transformAssetType(assettype) {
  return {
    id: assettype.id,
    value: assettype.id,
    label: assettype.name,
    order: assettype.objOrder,
    name: assettype.name,
    barCodePrefix: assettype.barCodePrefix,
    systemName: assettype.systemName,
    fileRefName: assettype.fileRefName,
    description: assettype.description,
    disabled: assettype.disabled,
    children: [],
    canDelete: assettype.deleteableOob,
    archived: assettype.removed,
    canEdit: assettype.updatebleOob,
    parentId: assettype.parentId,
    expanded: assettype.systemName === 'Base CI',
    uniqueNameIdentifier: assettype.uniqueNameIdentifier,
    assetTypePrefix: assettype.assetTypePrefix,
    ciTypePrefix: assettype.ciTypePrefix,
    iconSrc: assettype.fileRefName
      ? buildImageUrlFromRefFileName(assettype.fileRefName)
      : undefined,
    // iconAttachment: assettype.fileRefName
    //   ? [{ refFileName: assettype.fileRefName, realName: 'icon.png' }]
    //   : [],
  }
}

export function transformAssetTypeForServer(assettype) {
  return {
    name: assettype.name,
    barCodePrefix: assettype.barCodePrefix,
    disabled: assettype.disabled,
    fileRefName: assettype.fileRefName,
    assetTypePrefix: assettype.assetTypePrefix,
    uniqueNameIdentifier: assettype.uniqueNameIdentifier,
    ciTypePrefix: assettype.ciTypePrefix,
    assetTypeId: assettype.assetTypeId,
    parentId: assettype.parentId,
    description: assettype.description,
  }
}
