export function transformProductCatalog(productcatalog) {
  return {
    id: productcatalog.id,
    name: productcatalog.name,
    // text: `${productcatalog.name} ${productcatalog.productName}`,
    text: productcatalog.productName,
    displayName: productcatalog.productName,
    manufacturer: productcatalog.manufacturerId,
    productType: productcatalog.productTypeId,
    partNumber: productcatalog.partNumber,
    description: productcatalog.description,
    canDelete: productcatalog.deleteableOob,
    canEdit: productcatalog.updatebleOob,
    fieldValueDetails: productcatalog.fieldValueDetails,
  }
}

export function transformProductCatalogForServer(productcatalog) {
  return {
    id: productcatalog.id,
    name: productcatalog.name,
    productName: productcatalog.displayName,
    manufacturerId: productcatalog.manufacturer,
    productTypeId: productcatalog.productType,
    partNumber: productcatalog.partNumber,
    description: productcatalog.description,
    fieldValueDetails: productcatalog.fieldValueDetails,
  }
}
