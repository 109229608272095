import { generateId } from '@utils/id'
import SortBy from 'lodash/sortBy'
import Constants from '@constants'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformAvailableQualification,
  transformQualificationForServer,
  transformQualification,
} from './qualification'
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export const formRulesSupportedModules = [
  Constants.REQUEST,
  Constants.SERVICE_CATALOG,
  Constants.CHANGE,
  Constants.TASK,
]

const externalFormRulesQualifications = [
  {
    displayName: 'Status',
    key: 'request.statusId',
    relOperatorsSupport: ['in', 'not_in'],
    hierarchical: false,
    primitiveType: 'long',
    inputTypes: 'status',
    operandType: 'PropertyOperandRest',
    minHour: 0,
    maxHour: 0,
    group: 'request_fields',
  },
]

export const formRulesTabMap = (moduleName) => {
  return {
    planning_tab: 'change_activity',
    ...(moduleName === Constants.CHANGE
      ? {
          collaboration_tab: 'work',
          impact: 'impact',
          rolloutPlan: 'rolloutPlan',
          backoutPlan: 'backoutPlan',
        }
      : {
          work_tab: 'work',
        }),
    task_tab: 'task',
    approval_tab: 'approval',
    request_information_tab: 'request_information',
    relation_tab: 'relation',
    work_log_tab: 'work_log',
    notification_tab: 'email_notification',
    integration_tab: 'integration',
  }
}

export const mandatorySystemFieldParamNames = [
  'statusId',
  'urgencyId',
  'priorityId',
  'impactId',
  // for change module
  'riskTypeId',
]

export const ignoreSetValueFiledParamNames = (moduleName) => {
  if (moduleName === Constants.CHANGE) {
    return ['statusId']
  }
  return []
}

export function transformAction(action) {
  return {
    guid: generateId(),
    value: action.dbKeys,
    action: action.actionType,
    fieldId: action.fieldKey,
    fieldValue:
      action.actionType === 'filter_data'
        ? {
            userField: action.fieldValue[0],
            requestField: action.fieldValue[1],
          }
        : action.fieldValue,
    setFieldActionType: action.setFieldActionType,
  }
}

export function transformActionForServer(action, index) {
  return {
    dbKeys: action.value,
    actionType: action.action,
    fieldKey: action.fieldId,
    fieldValue:
      action.action === 'filter_data'
        ? [action.fieldValue.userField, action.fieldValue.requestField]
        : action.fieldValue,
    setFieldActionType: action.setFieldActionType,
    objOrder: index + 1,
  }
}

export const executionTypeMap = {
  create_and_edit: 'on_create_and_update',
  create: 'on_create',
  edit: 'on_update',
}

export const userTypeMap = {
  all: 'all_users',
  technician: 'all_technicians',
  requester: 'all_requesters',
  logged_in_user: 'all_logged_in_users',
}

export const eventTypeMap = {
  load: 'on_form_load',
  change: 'on_field_change',
  submit: 'on_form_submit',
}

export const ruleExecutionOptions = () => {
  return [
    {
      text: __rootT('on_create_and_update'),
      value: 'on_create_and_update',
    },
    { text: __rootT('on_create'), value: 'on_create' },
    {
      text: __rootT('on_update'),
      value: 'on_update',
    },
  ]
}

export const ruleApplicableOptions = () => {
  return [
    { text: __rootT('all_users'), value: 'all_users' },
    {
      text: __rootT('all_technicians'),
      value: 'all_technicians',
    },
    {
      text: __rootT('all_requesters'),
      value: 'all_requesters',
    },
    {
      text: __rootT('all_logged_in_users'),
      value: 'all_logged_in_users',
    },
  ]
}
export const ruleEventOptions = () => {
  return [
    { text: __rootT('on_form_load'), value: 'on_form_load' },
    {
      text: __rootT('on_field_change'),
      value: 'on_field_change',
    },
    {
      text: __rootT('on_form_submit'),
      value: 'on_form_submit',
    },
  ]
}
export const actionOptions = () => {
  return [
    { text: __rootT('show'), key: 'show', reverse: 'hide', type: 'attribute' },
    { text: __rootT('hide'), key: 'hide', reverse: 'show', type: 'attribute' },
    {
      text: __rootT('mandate'),
      key: 'mandate',
      reverse: 'non_mendate',
      type: 'attribute',
    },
    {
      text: __rootT('non_mendate'),
      key: 'non_mendate',
      reverse: 'mandate',
      type: 'attribute',
    },
    {
      text: __rootT('enable'),
      key: 'enable',
      reverse: 'disable',
      type: 'attribute',
    },
    {
      text: __rootT('disable'),
      key: 'disable',
      reverse: 'enable',
      type: 'attribute',
    },
    {
      text: __rootT('set_value'),
      key: 'set_value',
      reverse: 'clear_value',
      type: 'value',
    },
    {
      text: __rootT('clear_value'),
      key: 'clear_value',
      reverse: 'set_value',
      type: 'value',
    },
    {
      text: __rootT('show_options'),
      key: 'show_options',
      reverse: 'hide_options',
      type: 'option',
    },
    {
      text: __rootT('hide_options'),
      key: 'hide_options',
      reverse: 'show_options',
      type: 'option',
    },
    {
      text: __rootT('run_custom_script'),
      key: 'run_custom_script',
      reverse: 'run_custom_script',
      type: 'script',
    },
    {
      text: __rootT('filter_data'),
      key: 'filter_data',
      reverse: 'filter_data',
      type: 'filter_data',
    },
  ]
}

export function transformSupportData(moduleName, data) {
  return {
    qualifications: (data.qualPropList || [])
      .concat(
        moduleName === 'service_catalog' ? externalFormRulesQualifications : []
      )
      // remove requester, label, section field from qualification
      .filter((i) => {
        if (i.inputTypes === 'customfield') {
          return (
            ['section', 'label'].indexOf(i.field.subType) === -1 &&
            i.field.type !== 'AttachmentFieldRest'
          )
        }
        return (
          ['requesterEmail', 'description'].indexOf(i.inputTypes) === -1 &&
          i.key !== `${moduleName}.fileAttachments`
        )
      })
      .map(transformAvailableQualification),
    actions: (data.actionKeys || [])
      .map((o) => ({
        text: o.value,
        key: o.key,
      }))
      .concat(
        moduleName === Constants.SERVICE_CATALOG
          ? [
              {
                key: 'requester',
                text: __rootTc('requester'),
                disabled: false,
              },
              {
                key: 'statusId',
                text: __rootTc('status'),
                disabled: false,
              },
            ]
          : moduleName === Constants.CHANGE
          ? [
              {
                key: 'changeImplementor',
                text: __rootTc('implementer'),
                disabled: false,
              },
              {
                key: 'changeReviewer',
                text: __rootTc('reviewer'),
                disabled: false,
              },
              {
                key: 'changeManager',
                text: __rootTc('manager'),
                disabled: false,
              },
            ]
          : []
      ),
    tabs: (data.formRuleTabs || [])
      .map((t) => ({
        text: t.value,
        key: formRulesTabMap(moduleName)[t.key],
      }))
      .concat(
        moduleName === Constants.CHANGE
          ? [
              {
                text: `${__rootTc('planning')} - ${__rootTc('impact')}`,
                key: 'impact',
              },
              {
                text: `${__rootTc('planning')} - ${__rootTc('rolloutplan')}`,
                key: 'rolloutPlan',
              },
              {
                text: `${__rootTc('planning')} - ${__rootTc('backoutPlan')}`,
                key: 'backoutPlan',
              },
            ]
          : []
      ),
  }
}

export function transformFormRuleForServer(rule) {
  return {
    name: rule.name,
    description: rule.description,
    formRuleExecutionOn: rule.formRuleExecutionOn,
    formRuleUserType: rule.formRuleUserType,
    formRuleEvent: rule.formRuleEvent,
    fieldsChangedTrigger: rule.fieldsChangedTrigger,
    qualification: transformQualificationForServer(rule.qualifications),
    actions: (rule.actions || []).map(transformActionForServer),
    ...(((rule.qualifications || {}).groups || []).length
      ? { reversibleAction: rule.reversibleAction }
      : { reversibleAction: false }),
    ...('enabled' in rule ? { disabled: !rule.enabled } : {}),
  }
}

export function transformFormRuleForList(rule) {
  return {
    id: rule.id,
    name: rule.name,
    ...(rule.refId ? { parentId: rule.refId } : {}),
    formRuleExecutionOn: rule.formRuleExecutionOn,
    formRuleUserType: rule.formRuleUserType,
    formRuleEvent: rule.formRuleEvent,
    fieldsChangedTrigger: rule.fieldsChangedTrigger,
    moduleName: rule.refModel,
    qualifications: transformQualification(
      rule.qualification || { groups: [], operators: [] }
    ),
    actions: SortBy(rule.actions || [], 'objOrder').map(transformAction),
    reversibleAction: rule.reversibleAction,
    description: rule.description,
    canDelete: rule.deleteableOob,
    enabled: !rule.disabled,
    canEdit: rule.updatebleOob,
    createdAt: rule.createdTime,
  }
}
