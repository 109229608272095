import { transformRecursive } from './recursive'

export function transformDepartmentRecursive(departments) {
  return transformRecursive(departments, transformDepartment)
}

export function transformDepartment(department) {
  return {
    id: department.id,
    parentId: department.parentId,
    children: [],
    value: department.id,
    label: department.name,
    order: department.objOrder,
    name: department.name,
    disabled: department.disabled,
    archived: department.removed,
    description: department.description,
    departmentHead: department.departmentHead,
    departmentHeadName: department.departmentHeadName,
    canDelete: department.deleteableOob,
    canEdit: department.updatebleOob,
  }
}
export function transformDepartmentForServer(department) {
  return {
    parentId: department.parentId,
    name: department.name,
    description: department.description,
    departmentHead: department.departmentHead,
    departmentHeadName: department.departmentHeadName,
  }
}
