export function transformSnmpProperty(snmpproperty) {
  return {
    id: snmpproperty.id,
    oidLablel: snmpproperty.oidLablel,
    oid: snmpproperty.oid,
    fieldType: snmpproperty.fieldType,
    ...(snmpproperty.fieldType === 'tabular'
      ? { tableName: snmpproperty.tableName }
      : {}),
    canDelete: snmpproperty.deleteableOob,
    canEdit: snmpproperty.updatebleOob,
  }
}

export function transformSnmpPropertyForServer(snmpproperty) {
  return {
    oidLablel: snmpproperty.oidLablel,
    oid: snmpproperty.oid,
    fieldType: snmpproperty.fieldType,
    ...(snmpproperty.fieldType === 'tabular'
      ? { tableName: snmpproperty.tableName }
      : {}),
  }
}

export function transformSnmpPropertyValue(property) {
  return {
    id: property.id,
    snmpPropertyId: property.snmpPropertyId,
    oidLabel: property.snmpPropertyOidLablel,
    value: property.value,
    tableName: property.tableName,
    rowIndex: property.rowIndex,
    fieldType: property.fieldType,
    table: property.table,
    canDelete: property.deleteableOob,
    canEdit: property.updatebleOob,
  }
}
