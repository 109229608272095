export function transformCompaniesForList(company) {
  return {
    id: company.id,
    displayName: company.name,
    name: company.name,
    domains: company.domains,
    description: company.description,
    fieldValueDetails: company.fieldValueDetails,
    archived: company.removed,
  }
}

export function transformCompaniesForServer(company) {
  return {
    name: company.displayName,
    description: company.description,
    domains: company.domains,
    fieldValueDetails: company.fieldValueDetails,
  }
}
